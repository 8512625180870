<template>
    <div>
        <v-toolbar flat color="white" class=mb-1>
            <v-toolbar-title>Κατηγορίες Εκπαίδευσης</v-toolbar-title>
            <v-spacer></v-spacer>
            
            <!-- New cedu button -->
            <v-btn color="primary" text dark class="mb-2" @click="addCedu">
                <v-icon left>mdi-plus-circle-outline</v-icon>
                ΝΕΑ ΚΑΤΗΓΟΡΙΑ
            </v-btn>

            <!-- Refresh button -->
            <v-btn color="warning" text dark class="mb-2" @click="fetch">
                <v-icon left>mdi-replay</v-icon>
                ΑΝΑΝΕΩΣΗ
            </v-btn>
        </v-toolbar>

        <!-- Cedu form dialog -->
        <template>
            <v-dialog max-width="600px" persistent v-model="showCeduFormDlg">
                <v-card>
                    <v-card-title>
                        <span class="headline">{{ceduFormTitle}}</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-form ref="ceduForm" @keydown.native.enter.prevent="saveCedu">
                            <v-layout column>
                                <v-flex xs12>
                                    <v-text-field
                                        label="Συντομογραφία (*)"
                                        v-model="editedCedu.abbr"
                                        :rules="inputRules.abbr">
                                    </v-text-field>
                                </v-flex>
                                <v-flex xs12>
                                    <v-text-field
                                        label="Περιγραφή (*)"
                                        v-model="editedCedu.descr"
                                        :rules="inputRules.descr">
                                    </v-text-field>
                                </v-flex>
                            </v-layout>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="saveCedu" :loading="ceduFormLoading">ΑΠΟΘΗΚΕΥΣΗ</v-btn>
                        <v-btn color="red darken-1" text @click="closeCeduFormDlg">ΑΚΥΡΩΣΗ</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>

        <!-- Datatable -->
        <v-data-table
            :headers="headers"
            :items = "cedus"
            must-sort
            :loading="listLoading"
            loadingText="Ανάκτηση εγγραφών"
            :sort-by.sync="options.sortBy"
            :sort-desc.sync="options.sortDesc"
            :items-per-page.sync="options.itemsPerPage"
            no-data-text="Δεν υπάρχουν κατηγορίες εκπαίδευσης"
            class="elevation-1"
            :footer-props="{
                'items-per-page-text': 'Εγγραφές ανά σελίδα'
            }"
        >
            <template slot="footer.page-text" slot-scope="item">
                Εγγραφές {{item.pageStart}} έως {{item.pageStop}} από {{item.itemsLength}}
            </template>
            <template v-slot:item.updated_at="{item}">
                <div>{{ item.updated_at | moment("DD/MM/YYYY") }}, {{ item.updated_at | moment("HH:mm") }}</div>
            </template>
            <template v-slot:item.actions="{item}">
                <div class="layout">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                class="mr-2"
                                @click="editCedu(item)"
                                color="blue"
                                v-on="on">
                                mdi-lead-pencil
                            </v-icon>
                        </template>
                        <span>Επεξεργασία κατηγορίας</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                class="mr-2"
                                @click="deleteCedu(item)"
                                color="red"
                                v-on="on">
                                mdi-delete
                            </v-icon>
                        </template>
                        <span>Διαγραφή κατηγορίας</span>
                    </v-tooltip>
                </div>
            </template>

        </v-data-table>
    </div>
</template>

<script>
import axios from '@/plugins/axios'
import { EventBus } from '@/app.js'

export default {
    components: {
        
    },
    computed: {
        ceduFormTitle() {
            if (!this.editedCedu.id) {
                return "Νέα κατηγορία εκπαίδευσης"
            }
            else {
                return "Επεξεργασία κατηγορίας εκπαίδευσης"
            }
        },
    },
    watch: {
        showCeduFormDlg(val) {
            if (val && this.$refs.ceduForm) {
                this.$refs.ceduForm.resetValidation();
            }
        }
    },
    data() {
        return {
            cedus: [],
            headers: [
                {text: "Ενημέρωση", value: "updated_at"},
                {text: "Συντομογραφία", value: "abbr"},
                {text: "Περιγραφή", value: "descr"},
                {text: "Επαφές", value: "clients_n"},
                {text: "Ενέργειες", value: "actions", sortable: false}
            ],
            options: {
                sortBy: 'updated_at',
                sortDesc: true,
                itemsPerPage: 10,
            },
            inputRules: {
                abbr: [
                    v => !!v || "Πληκτρολογείστε την συντομογραφία της κατηγορίας",
                ],
                descr: [
                    v => !!v || "Πληκτρολογείστε την περιγραφή της κατηγορίας",
                ],
            },
            listLoading: false,
            showCeduFormDlg: false,
            currentCedu: this.nullCedu(),
            editedCedu: this.nullCedu(),
            ceduFormLoading: false,
        }
    },
    created() {
        this.fetch();
    },
    methods: {
        fetch() {
            this.listLoading = true;
            axios.get('/cedus', {
                headers: {Authorization: this.$store.getters.authHeader}
            }).then(response => {
                this.cedus = response.data;
                this.listLoading = false;
            }).catch(error => {
                this.listLoading = false;
                EventBus.$emit('error-message', 'Σφάλμα κατά την ανάκτηση των κατηγοριών εκπαίδευσης')
            });
        },
        deleteCedu(cedu) {
            // Confirm and delete
            this.$confirm(`Να διαγραφεί η κατηγορία "${cedu.abbr}"`, {title: "Διαγραφή κατηγορίας"})
            .then(res => {
                if (res) {
                    axios.delete(`/cedus/${cedu.id}`, {
                        headers: {Authorization: this.$store.getters.authHeader}
                    }).then((response) => {
                        var index = this.cedus.findIndex(c => c.id == cedu.id);
                        this.cedus.splice(index, 1);
                        EventBus.$emit('success-message', `Επιτυχής διαγραφή της κατηγορίας εκπαίδευσης "${cedu.abbr}"`);
                    }).catch(error => {
                        EventBus.$emit('error-message', error.response.data.message);
                    });
                }
            });
        },
        addCedu() { // Open cedu form
            this.editedCedu = this.nullCedu();
            this.showCeduFormDlg = true;
        },
        editCedu(cedu) {
            this.currentCedu = cedu;
            this.copyCurrentToEditedCedu();
            this.showCeduFormDlg = true;
        },
        closeCeduFormDlg() {
            this.showCeduFormDlg = false;
            this.$refs.ceduForm.resetValidation();
        },
        saveCedu() {
            if (!this.$refs.ceduForm.validate()) return;
            this.$refs.ceduForm.resetValidation();
            this.ceduFormLoading=true;
            if (!this.editedCedu.id) this.createCedu(this.editedCedu);
            else this.updateCedu(this.editedCedu);
        },
        createCedu(newCedu) {
            axios.post('/cedus', newCedu, {headers: {Authorization: this.$store.getters.authHeader}})
            .then(response => {
                this.ceduFormLoading = false;
                this.closeCeduFormDlg();
                this.editedCedu = response.data;
                this.copyEditedToCurrentCedu();
                this.cedus.push(this.editedCedu);
                EventBus.$emit('success-message', `Η κατηγορία εκπαίδευσης "${newCedu.abbr}" δημιουργήθηκε με επιτυχία`);
            }).catch(error => {
                this.ceduFormLoading = false;
                this.closeCeduFormDlg();
                EventBus.$emit('error-message', 'Σφάλμα κατά την αποθήκευση των στοιχείων της κατηγορίας εκπαίδευσης');
            });
        },
        updateCedu(newCedu) {
            axios.put(`/cedus/${newCedu.id}`, newCedu, {headers: {Authorization: this.$store.getters.authHeader}})
            .then(response => {
                this.ceduFormLoading = false;
                this.closeCeduFormDlg();
                this.editedCedu = response.data;
                this.copyEditedToCurrentCedu();
                EventBus.$emit('success-message', `Τα στοιχεία της κατηγορίας '${newCedu.abbr}' ενημερώθηκαν με επιτυχία`);
            }).catch(error => {
                this.ceduFormLoading = false;
                this.closeCeduFormDlg();
                EventBus.$emit('error-message', 'Σφάλμα κατά την αποθήκευση των στοιχείων της κατηγορίας εκπαίδευσης');
            });
        },
        copyEditedToCurrentCedu() {
            this.currentCedu.id =  this.editedCedu.id;
            this.currentCedu.abbr = this.editedCedu.abbr;
            this.currentCedu.descr = this.editedCedu.descr;
            this.currentCedu.clients_n = this.editedCedu.clients_n;
            this.currentCedu.created_at = this.editedCedu.created_at;
            this.currentCedu.updated_at = this.editedCedu.updated_at;
        },
        copyCurrentToEditedCedu() {
            this.editedCedu.id = this.currentCedu.id;
            this.editedCedu.abbr = this.currentCedu.abbr;
            this.editedCedu.descr = this.currentCedu.descr;
            this.editedCedu.clients_n = this.currentCedu.clients_n;
            this.editedCedu.created_at = this.currentCedu.created_at;
            this.editedCedu.updated_at = this.currentCedu.updated_at;
        },
        nullCedu() {
            return {
                id: null,
                abbr: null,
                descr: null,
                clients_n: null,
                created_at: null,
                updated_at: null
            };
        }
    }
}
</script>

<style>

</style>