export default {
    appTitle: "Work & Study",
    user: {
        id: sessionStorage.getItem('user_id') ? parseInt(sessionStorage.getItem('user_id')) : null,
        name: sessionStorage.getItem('user_name') || null,
        email: sessionStorage.getItem('user_email') || null,
        active: sessionStorage.getItem('user_active') || null,
        admin: sessionStorage.getItem('user_admin') || null,
        token: sessionStorage.getItem('user_token') || null,
        token_id: sessionStorage.getItem('user_token_id') || null,
        created_at: sessionStorage.getItem('user_created_at') || null,
        updated_at: sessionStorage.getItem('user_updated_at') || null
    }
}