<template>
  <v-container fluid fill-height>
  <v-layout align-center justify-center row>
    <v-flex xs12 sm8 md4>
      <v-card class="elevation-12 mx-auto" width="500">
        <v-card-text>
          <v-img src="/storage/logo1.png" class="mb-4"></v-img>  
          <div v-if="invalidCredentials" class="red--text">Μη αποδεκτά διαπιστευτήρια</div>
          <v-form ref="form" @keydown.native.enter.prevent="login">
            <v-text-field
              id="email"
              autocomplete="disabled"
              prepend-icon="mdi-account"
              name="email"
              label="E-mail"
              type="email"
              v-model="user.email"
              :rules="inputRules.email"
            ></v-text-field>
            <v-text-field
              ref="password"
              prepend-icon="mdi-lock"
              name="password"
              label="Κωδικός"
              id="password"
              type="password"
              v-model="user.password"
              :rules="inputRules.password"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn class="mb-3" color="primary" @click="login" :loading="loading">ΣΥΝΔΕΣΗ</v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</v-container>
</template>

<script>
export default {
  mounted() {
    document.getElementById('email').click();
  },
  data() {
    return {
      user: {
        email: "",
        password: ""
      },
      loading: false,
      invalidCredentials: false,
      inputRules: {
        email: [
          v => !!v || "Πληκτρολογείστε το email του χρήστη",
          v => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(v) || 'Μη έγκυρη διεύθυνση e-mail'
          }
        ],
        password: [
          v => !!v || "Πληκτρολογείστε τον κωδικό πρόσβασης"
        ]
      }
      
    };
  },
  methods: {
    login() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;
      var user = this.user;
      this.$store.dispatch("login", user).then(response => {
        this.$router.push({name: 'dashboard'});
        this.loading = false;
        this.invalidCredentials = false;
      }).catch(error => {
        this.invalidCredentials = true;
        this.loading = false;
      });
        
    }
  }
};
</script>


