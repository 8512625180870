<template>
  <div>
        <v-dialog max-width="600px" persistent v-model="value">
            <v-card>
                <v-card-title>
                    <span class="headline">Επεξεργασία σημειώσεων</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <wysiwyg v-model="localNotes"></wysiwyg>
                </v-card-text>
                <v-card-actions>  
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text :loading="loading" @click="onSave">ΑΠΟΘΗΚΕΥΣΗ</v-btn>
                    <v-btn color="red darken-1" text @click="onCancel">ΑΚΥΡΩΣΗ</v-btn>
                    </v-card-actions>
            </v-card>
        </v-dialog>
  </div>
</template>

<script>
import axios from '@/plugins/axios'

export default {
    props: {
        value: {
            required: true
        },
        url: {
            required: true
        },
        notes: {
            required: false,
            default: ""
        },
        fieldName: {
            required: false,
            default: 'notes'
        }
    },
    mounted() {
        this.localNotes = this.notes;
    },
    watch: {
        notes(val) {
            this.localNotes = val;
        }
    },
    data() {
        return {
            localNotes: "",
            loading: false,
        }
    },
    methods: {
        onSave() {
            this.loading = true;
            var formData = new FormData();
            formData.append(this.fieldName, this.localNotes);
            axios.post(this.url, formData, {headers: {Authorization: this.$store.getters.authHeader}})
            .then(response => {
                this.loading = false;
                this.localNotes = "";
                this.$emit('save', response.data);
            }).catch(error => {
                this.loading = false;
                this.localNotes = "";
                this.$emit('error', error);
            });

        },
        onCancel() {
            this.$emit('cancel');
            this.localNotes = "";
        }
    }
}
</script>

<style>

</style>