<template>

<v-app>

    <!-- Toolbar -->
    <v-app-bar app dark clipped-left v-if="this.$store.getters.isAuth">
        <v-app-bar-nav-icon @click.stop="drawer.show = !drawer.show"></v-app-bar-nav-icon>

        <v-toolbar-title>{{this.$store.getters.appTitle}}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-menu offset-y>
            <template v-slot:activator="{on}">
                <v-btn text class="text-none" v-on="on">
                    <v-icon left>mdi-account-circle</v-icon>
                    {{authUser.email}}
                </v-btn>
            </template>
            <v-list>
                <v-list-item link v-for="item in userMenu" :key="item.title" :to="item.link">
                    <v-list-item-action>
                        <v-icon left>{{item.icon}}</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{item.title}}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>


    </v-app-bar>

    <!-- Navigation drawer -->
    <v-navigation-drawer
        v-if="this.$store.getters.isAuth"
        v-model="drawer.show"
        clipped app dark fixed
    >
        <v-img src="/storage/logo1.png"></v-img>

        <v-list class="mt-2">

            <!-- Menu items -->
            <v-list-item link v-for="item in navMenu" :key=item.title :to="item.link">
                <v-list-item-icon>
                    <v-icon>{{item.icon}}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{item.title}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

        </v-list>

    </v-navigation-drawer>
    
    <!-- Snackbar -->
    <v-snackbar
        v-model="snackbar.show"
        :bottom = "snackbar.y === 'bottom'"
        :top = "snackbar.y === 'top'"
        :left = "snackbar.x === 'left'"
        :right = "snackbar.x === 'right'"
        :color="snackbar.color"
        :multi-line="snackbar.mode === 'multi-line'"
        :timeout="snackbar.timeout"
        :vertical="snackbar.mode === 'vertical'"
    >
        {{ snackbar.message }}
        
        <template v-slot:action="{ attrs }">
            <v-btn
                text
                v-bind="attrs"
                @click="snackbar.show = false"
            >
            X
            </v-btn>
        </template>
    </v-snackbar>

    <!-- Main Content -->
    <v-main>
        <v-container fluid>
            <router-view></router-view>
        </v-container>
    </v-main>

    <!-- Footer -->
    <v-footer inset dark app v-if="this.$store.getters.isAuth">
        <span class="white--text">&copy; {{ new Date().getFullYear() }} Τσακιρίδης Σωτήριος</span>
    </v-footer>


</v-app>

  
</template>

<script>
import { EventBus } from '@/app.js';
import params from '@/params.js';

export default {
    created() {
        EventBus.$on('success-message', message => {
            this.showSnackbar('success', message);
        });
        EventBus.$on('info-message', message => {
            this.showSnackbar('info', message);
        });
        EventBus.$on('waring-message', message => {
            this.showSnackbar('waring', message);
        });
        EventBus.$on('error-message', message => {
            this.showSnackbar('error', message);
        });
    },
    computed: {
        authUser() {
            return this.$store.getters.user;
        } 
    },

    data() {
        return {
            drawer: {
                show: true,
                color: 'primary',
                permanent: true,
                expandOnHover: false,
                background: false,
                items: [
                    { title: 'Dashboard', icon: 'mdi-view-dashboard' },
                    { title: 'Photos', icon: 'mdi-image' },
                    { title: 'About', icon: 'mdi-help-box' }
                ],
            },
            snackbar: {
                show: false,
                color: "success",
                mode: 'multi-line',
                timeout: params.SNACKBAR_TIMEOUT,
                x: null,
                y: 'top',
                message: ''
            },
            userMenu: [
                {title: "Αποσύνδεση", icon: "mdi-logout", link: "/logout"}
            ],
           navMenu: [
                {title: "Επαφές", icon: "mdi-table-account", link: "/clients"},
                {title: "Εκπαίδευση", icon: "mdi-school", link: "/cedus"},
                {title: "Υπηρεσίες", icon: "mdi-offer", link: "/csrvs"},
                {title: "Χρήστες", icon: "mdi-account-multiple", link: "/users"},
            ],
        };
    },
    methods: {
        showSnackbar(color, message) {
            this.snackbar.color = color;
            this.snackbar.message = message;
            this.snackbar.show = true;
        }
    }
}
</script>

