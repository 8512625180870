import moment from 'moment'

export function formatDate(date) {
    if (!date) return null;

    return moment(date).format('DD/MM/YYYY');
}

export function cloneUser(user) {
    return {
        id: user.id,
        name: user.name,
        email: user.email,
        password: user.password,
        active: user.active,
        admin: user.admin,
        token: user.token,
        token_id: user.token_id,
        created_at: user.created_at,
        updated_at: user.updated_at
    }
};


export function nullUser() {
    return {
        id: null,
        name: null,
        email: null,
        password: null,
        active: null,
        admin: null,
        token: null,
        token_id: null,
        created_at: null,
        updated_at: null
    }
}


export function nullClient() {
    return {
            id: null,
            active: null,
            lastname: null,
            firstname: null,
            fathername: null,
            mothername: null,
            avatar: null,
            birthdate: null,
            sex: null,
            nationality: null,
            adt: null,
            afm: null,
            amka: null,
            email: null,
            phone: null,
            mobil: null,
            fax: null,
            address: null,
            area: null,
            city: null,
            tk: null,
            notes: null,
            created_at: null,
            updated_at: null,
            created_by: null,
            updated_by: null
    };
}


export function resetObject(obj) {
    Object.keys(obj).forEach(function(idx) {
        obj[idx] = null;
    });
}