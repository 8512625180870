<template>
    <div style="clear: both">
        <div class="label">{{label}}:</div>
        <div class="data">{{data}}</div>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            required: true
        },
        data: {
            required: true
        }
    }
}
</script>

<style scoped>


.label {
    float: left;
    width: 160px;
    clear: both;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: bold;
    display: block;
}

.data {
    margin-left: 180px;
    display: block;
}

</style>