<template>
  <div>
        <v-card>
            <v-card-title>
                <span class="headline">Επιλογή αρχείου</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-form ref="form" @keydown.native.enter.prevent="onSave">
                    <v-layout column>
                        <v-flex>
                            <v-file-input
                                v-model="inputFile"
                                :label="label"
                                :rules="inputRules.required">
                            </v-file-input>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions>  
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text :loading="loading" @click="onUpload">ΑΠΟΔΟΧΗ</v-btn>
                <v-btn color="red darken-1" text @click="onCancel">ΑΚΥΡΩΣΗ</v-btn>
            </v-card-actions>
        </v-card>
  </div>
</template>

<script>
import axios from '@/plugins/axios'

export default {
    props: {
        label: {
            required: false,
            default: "Επιλογή αρχείου"
        },
        url: {
            required: true
        },
        fieldName: {
            required: false,
            default: "file"
        }
    },
    data() {
        return {
            inputFile: null,
            inputRules: {
                required: [
                    v => !!v || "Δεν έχετε επιλέξει αρχείο",
                ]
            },
            loading: false
        }
    }, 
    computed: {

    },
    methods: {
        onUpload() {
            if (!this.$refs.form.validate()) return;
            this.loading = true;
            var formData = new FormData();
            formData.append(this.fieldName, this.inputFile);
            axios.post(this.url, formData, {headers: {
                Authorization: this.$store.getters.authHeader,
                'Content-Type': 'multipart/form-data'
            }})
            .then(response => {
                this.loading = false;
                this.inputFile = null;
                this.$refs.form.resetValidation();
                this.$emit("uploaded", response.data);
            }).catch(error => {
                this.loading = false;
                this.inputFile = null;
                this.$refs.form.resetValidation();
                this.$emit("error", error);
            });
        },
        onCancel() {
            this.$refs.form.resetValidation();
            this.$emit("cancel")
            this.inputFile = null;
        }
    }
}
</script>

<style>

</style>