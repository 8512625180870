import Vue from 'vue'
import Router from 'vue-router'
import AuthGuard from './auth-guard'
import GuestGuard from './guest-guard'

// Components
import Dashboard from '../components/Dashboard/Home'
import ClientCreate from '../components/Clients/Create'
import ClientEdit from '../components/Clients/Edit'
import ClientList from '../components/Clients/List'
import ClientShow from '../components/Clients/Show'
import CeduList from '../components/Clients/Cedus/List'
import SrvList from '../components/Srvs/List'
import SrvCreate from '../components/Srvs/Create'
import CsrvList from '../components/Csrvs/List'
import UserList from '../components/Users/List'
import UserCreate from '../components/Users/Create'
import Login from '../components/Users/Login'
import Logout from '../components/Users/Logout'


Vue.use(Router)

export default new Router({
    routes: [

        // Dashboard
        {
            path: '/',
            redirect: '/clients',
            name: 'dashboard',
            component: Dashboard,
            beforeEnter: AuthGuard
        },

        // Clients
        {
            path: '/clients',
            name: 'clientList',
            component: ClientList,
            beforeEnter: AuthGuard
        },
        {
            path: '/clients/:client_id',
            name: 'clientShow',
            component: ClientShow,
            beforeEnter: AuthGuard,
            props: true,
        },
        {
            path: '/clients-create',
            name: 'clientCreate',
            component: ClientCreate,
            beforeEnter: AuthGuard
        },
        {
            path: '/clients-edit/:client_id',
            name: 'clientEdit',
            component: ClientEdit,
            beforeEnter: AuthGuard,
            props: true,
        },
        {
            path: '/cedus',
            name: 'ceduList',
            component: CeduList,
            beforeEnter: AuthGuard
        },

        // Services
        {
            path: '/srvs',
            name: 'srvList',
            component: SrvList,
            beforeEnter: AuthGuard
        },
        {
            path: '/srvs-create',
            name: 'srvCreate',
            component: SrvCreate,
            beforeEnter: AuthGuard
        },
        {
            path: '/csrvs',
            name: 'csrvList',
            component: CsrvList,
            beforeEnter: AuthGuard
        },

        // Users
        {
            path: '/login',
            name: 'login',
            component: Login,
            beforeEnter: GuestGuard
        },
        {
            path: '/logout',
            name: 'logout',
            component: Logout,
            beforeEnter: AuthGuard
        },
        {
            path: '/users',
            name: 'userList',
            component: UserList,
            beforeEnter: AuthGuard
        },
        {
            path: '/users-create',
            name: 'userCreate',
            component: UserCreate,
            beforeEnter: AuthGuard
        },

      

    ],
    mode: "history"
});