var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[[_c('v-dialog',{attrs:{"max-width":"600px","persistent":""},model:{value:(_vm.showFormDlg),callback:function ($$v) {_vm.showFormDlg=$$v},expression:"showFormDlg"}},[_c('edu-form',{attrs:{"edu":_vm.current},on:{"created":_vm.onCreateEdu,"updated":_vm.onUpdateEdu,"cancel":function($event){_vm.showFormDlg=false}}})],1)],_vm._v(" "),[_c('v-dialog',{attrs:{"max-width":"600px","persistent":""},model:{value:(_vm.showUploadFileDlg),callback:function ($$v) {_vm.showUploadFileDlg=$$v},expression:"showUploadFileDlg"}},[_c('upload-file',{attrs:{"label":"Πιστοποιητικού σπουδών","fieldName":"certification","url":_vm.uploadCertificationUrl},on:{"uploaded":_vm.onUploadCertification,"cancel":function($event){_vm.showUploadFileDlg=false}}})],1)],_vm._v(" "),_c('v-card',{attrs:{"height":"100%"}},[_c('v-card-title',[_vm._v("\n            Στοιχεία εκπαίδευσης\n            "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","text":"","dark":""},on:{"click":_vm.createEdu}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-circle-outline")]),_vm._v("\n                ΝΕΟΣ ΤΙΤΛΟΣ\n            ")],1)],1),_vm._v(" "),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.localEdus,"items-per-page":_vm.options.itemsPerPage,"no-data-text":"Δεν υπάρχουν επαφές","footer-props":{
                    'items-per-page-text': 'Εγγραφές ανά σελίδα'
                }},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.options, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.options, "itemsPerPage", $event)}},scopedSlots:_vm._u([{key:"item.certification",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"text-center"},[(item.certification)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function($event){return _vm.viewCertification(item)}}},on),[_vm._v("\n                                    mdi-file-certificate-outline\n                                ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Προβολή πιστοποιητικού")])]):_vm._e()],1)]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"layout"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"blue"},on:{"click":function($event){return _vm.editEdu(item)}}},on),[_vm._v("\n                                    mdi-lead-pencil\n                                ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Επεξεργασία τίτλου")])]),_vm._v(" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteEdu(item)}}},on),[_vm._v("\n                                    mdi-delete\n                                ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Διαγραφή τίτλου")])]),_vm._v(" "),(item.certification)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"orange"},on:{"click":function($event){return _vm.deleteCertification(item)}}},on),[_vm._v("\n                                    mdi-file-remove-outline\n                                ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Διαγραφή πιστοποιητικού")])]):_vm._e(),_vm._v(" "),(!item.certification)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"green"},on:{"click":function($event){return _vm.uploadCertification(item)}}},on),[_vm._v("\n                                    mdi-file-plus-outline\n                                ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Μεταφόρτωση πιστοποιητικού")])]):_vm._e()],1)]}}])})],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }