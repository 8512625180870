<template>
    <div>
        <!-- Edu form dialog -->
        <template>
            <v-dialog max-width="600px" persistent v-model="showFormDlg">
                <edu-form 
                    :edu="current" 
                    @created="onCreateEdu"
                    @updated="onUpdateEdu" 
                    @cancel="showFormDlg=false">
                </edu-form>
            </v-dialog>
        </template>

        <!-- upload file dialog -->
        <template>
            <v-dialog max-width="600px" persistent v-model="showUploadFileDlg">
                <upload-file
                    label="Πιστοποιητικού σπουδών" 
                    fieldName="certification"
                    :url="uploadCertificationUrl"
                    @uploaded="onUploadCertification"
                    @cancel="showUploadFileDlg=false">
                </upload-file>
            </v-dialog>
        </template>

        <v-card height="100%">
            <v-card-title>
                Στοιχεία εκπαίδευσης
                <v-spacer></v-spacer>
                <v-btn color="primary" text dark class="mb-2" @click="createEdu">
                    <v-icon left>mdi-plus-circle-outline</v-icon>
                    ΝΕΟΣ ΤΙΤΛΟΣ
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-data-table dense
                    :headers="headers"
                    :items = "localEdus"
                    :items-per-page.sync="options.itemsPerPage"
                    no-data-text="Δεν υπάρχουν επαφές"
                    class="elevation-1"
                    :footer-props="{
                        'items-per-page-text': 'Εγγραφές ανά σελίδα'
                    }">
                    <template v-slot:item.certification="{item}">
                        <div class="text-center">
                            <v-tooltip top v-if="item.certification">
                                <template v-slot:activator="{ on }">
                                    <v-icon
                                        @click="viewCertification(item)"
                                        v-on="on">
                                        mdi-file-certificate-outline
                                    </v-icon>
                                </template>
                                <span>Προβολή πιστοποιητικού</span>
                            </v-tooltip>
                        </div>
                    </template>
                    <template v-slot:item.actions="{item}">
                        <div class="layout">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-icon
                                        class="mr-2"
                                        @click="editEdu(item)"
                                        color="blue"
                                        v-on="on">
                                        mdi-lead-pencil
                                    </v-icon>
                                </template>
                                <span>Επεξεργασία τίτλου</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-icon
                                        class="mr-2"
                                        @click="deleteEdu(item)"
                                        color="red"
                                        v-on="on">
                                        mdi-delete
                                    </v-icon>
                                </template>
                                <span>Διαγραφή τίτλου</span>
                            </v-tooltip>
                            <v-tooltip top v-if="item.certification">
                                <template v-slot:activator="{ on }">
                                    <v-icon
                                        class="mr-2"
                                        @click="deleteCertification(item)"
                                        color="orange"
                                        v-on="on">
                                        mdi-file-remove-outline
                                    </v-icon>
                                </template>
                                <span>Διαγραφή πιστοποιητικού</span>
                            </v-tooltip>
                            <v-tooltip top v-if="!item.certification">
                                <template v-slot:activator="{ on }">
                                    <v-icon
                                        class="mr-2"
                                        @click="uploadCertification(item)"
                                        color="green"
                                        v-on="on">
                                        mdi-file-plus-outline
                                    </v-icon>
                                </template>
                                <span>Μεταφόρτωση πιστοποιητικού</span>
                            </v-tooltip>
                        </div>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </div>
  
</template>

<script>
import EduForm from './Form'
import axios from '@/plugins/axios'
import { EventBus } from '@/app.js'
import {resetObject} from '@/util.js'
import UploadFile from '@/components/UploadFile'

export default {
    props: {
        edus: {
            required: true
        },
        client_id: {
            required: true
        }
    },
    components: {
        'edu-form': EduForm,
        'upload-file': UploadFile,
    },
    mounted() {
        // copy props to local to make them mutable
        this.localEdus = this.edus;
    },
    computed: {
        headers() {
            return [
                {text: "Κατηγορία", value: "cedu.abbr", sortable: false},
                {text: "Ίδρυμα", value: "idrima", sortable: false},
                {text: "Σχολή", value: "sxoli", sortable: false},
                {text: "Τίτλος", value: "titlos", sortable: false},
                {text: "Αποφοίτηση", value: "grad_year", sortable: false},
                {text: "Βαθμός", value: "grade", sortable: false},
                {text: "Αρχείο", value: "certification", sortable: false},
                {text: "Ενέργειες", value: "actions", sortable: false}
            ]
        }
    },
    data() {
        return {
            localEdus: [],
            options: {
                itemsPerPage: -1,
            },
            showFormDlg: false,
            showUploadFileDlg: false,
            uploadCertificationUrl: '',
            current: {},
        }
    },
    methods: {
        createEdu() {
            this.resetCurrent();
            this.showFormDlg = true;
        },
        editEdu(edu) {
            this.current=edu;
            this.showFormDlg = true;
        },
        deleteEdu(edu) {
            // Confirm and delete
            this.$confirm(`Να διαγραφεί ο επιλεγμένος τίτλος σπουδών?`, {title: "Διαγραφή τίτλου σπουδών"})
            .then(res => {
                if (res) {
                    axios.delete(`/edus/${edu.id}`, {
                        headers: {Authorization: this.$store.getters.authHeader}
                    }).then((response) => {
                        var idx = this.localEdus.findIndex(x => x.id == edu.id);
                        this.localEdus.splice(idx, 1);
                        EventBus.$emit('success-message', `Επιτυχής διαγραφή του τίτλου σπουδών`);
                    }).catch(error => {
                        EventBus.$emit('error-message', 'Σφάλμα κατά τη διαγραφή του τίτλου σπουδών');
                    });
                }
            });
        },
        onCreateEdu(edu) {
            this.showFormDlg = false;
            this.localEdus.push(edu);
            EventBus.$emit('success-message', `Ο τίτλος σπουδών καταχωρήθηκε με επιτυχία`);
        },
        onUpdateEdu(edu) {
            this.showFormDlg = false;
            //var idx = this.edus.findIndex(x => x.id = edu.id);
            Object.assign(this.current, edu);
            EventBus.$emit('success-message', `Τα στοιχεία του τίτλου σπουδών ενημερώθηκαν με επιτυχία`);
        },
        onError(error) {
            this.showFormDlg = false;
            EventBus.$emit('error-message', `Σφάλμα κατά την αποθήκευση του τίτλου σπουδών`);
        },
        viewCertification(edu) {
            window.open(edu.certification);
        },
        uploadCertification(edu) {
            this.uploadCertificationUrl = `/edus/${edu.id}/cert`;
            this.showUploadFileDlg = true;
        },
        onUploadCertification(edu) {
            this.showUploadFileDlg = false;
            var idx = this.localEdus.findIndex(x => x.id == edu.id);
            this.localEdus[idx].certification = edu.certification;
        },
        deleteCertification(edu) {
            // Confirm and delete
            this.$confirm(`Να διαγραφεί το πιστοποιητικό του τίτλου σπουδών?`, {title: "Διαγραφή πιστοποιητικού"})
            .then(res => {
                if (res) {
                    axios.delete(`/edus/${edu.id}/cert`, {
                        headers: {Authorization: this.$store.getters.authHeader}
                    }).then((response) => {
                        var idx = this.localEdus.findIndex(x => x.id == edu.id);
                        this.localEdus[idx].certification=null;
                        EventBus.$emit('success-message', `Επιτυχής διαγραφή του πιστοποιητικού`);
                    }).catch(error => {
                        EventBus.$emit('error-message', 'Σφάλμα κατά τη διαγραφή του πιστοποιητικού');
                    });
                }
            });
        },
        resetCurrent() {
            this.current = {
                id: null,
                client_id: this.client_id,
                cedu: null,
                idrima: null,
                sxoli: null,
                titlos: null,
                grad_year: null,
                grade: null,
                certification: null,
                created_at: null,
                updadted_at: null
            }
        }
    }

}
</script>

<style>

</style>