<template>
    <div>
        <!-- Srv form dialog -->
        <template>
            <v-dialog max-width="600px" persistent v-model="showFormDlg">
                <srv-form 
                    :srv="current" 
                    @created="onCreateSrv"
                    @updated="onUpdateSrv" 
                    @cancel="showFormDlg=false">
                </srv-form>
            </v-dialog>
        </template>

        <v-card height="100%">
            <v-card-title>
                Αιτήσεις
                <v-spacer></v-spacer>
                <v-btn color="primary" text dark class="mb-2" @click="createSrv">
                    <v-icon left>mdi-plus-circle-outline</v-icon>
                    ΝΕΑ ΑΙΤΗΣΗ
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-data-table 
                    :headers="headers"
                    :items = "localSrvs"
                    :items-per-page.sync="options.itemsPerPage"
                    no-data-text="Δεν υπάρχουν αιτήσεις"
                    class="elevation-1"
                    :footer-props="{
                        'items-per-page-text': 'Εγγραφές ανά σελίδα'
                    }"
                    :expanded.sync="expanded"
                    show-expand
                >
                    <template v-slot:expanded-item="{headers, item}">
                        <td :colspan="headers.length">
                            <docs-list 
                                :docs="item.docs"
                                :srv_id="item.id" 
                                class="my-4">
                            </docs-list>
                        </td>
                    </template>
                    <template v-slot:item.apply="{item}">
                        <div>{{ item.apply | moment("DD/MM/YYYY") }}</div>
                    </template>
                    <template v-slot:item.actions="{item}">
                        <div class="layout">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-icon
                                        class="mr-2"
                                        @click="editSrv(item)"
                                        color="blue"
                                        v-on="on">
                                        mdi-lead-pencil
                                    </v-icon>
                                </template>
                                <span>Επεξεργασία αίτησης</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-icon
                                        class="mr-2"
                                        @click="deleteSrv(item)"
                                        color="red"
                                        v-on="on">
                                        mdi-delete
                                    </v-icon>
                                </template>
                                <span>Διαγραφή αίτησης</span>
                            </v-tooltip>
                        </div>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </div>
  
</template>

<script>
import SrvForm from './Form'
import axios from '@/plugins/axios'
import { EventBus } from '@/app.js'
import moment from 'moment'
import DocsList from '@/components/Docs/List'

export default {
    props: {
        srvs: {
            required: true
        },
        client_id: {
            required: true
        }
    },
    components: {
        'srv-form': SrvForm,
        'docs-list': DocsList,
    },
    mounted() {
        // copy props to local to make them mutable
        this.localSrvs = this.srvs;
    },
    computed: {
        headers() {
            return [
                {text: "Υποβολή", value: "apply", sortable: false},
                {text: "Περιγραφή", value: "csrv.descr", sortable: false},
                {text: "Σημειώσεις", value: "notes", sortable: false},
                {text: "Ενέργειες", value: "actions", sortable: false},
                {text: "", value: "data-table-expand"}
            ]
        }
    },
    data() {
        return {
            localSrvs: [],
            expanded: [],
            options: {
                itemsPerPage: -1,
            },
            showFormDlg: false,
            current: {},
        }
    },
    methods: {
        createSrv() {
            this.resetCurrent();
            this.showFormDlg = true;
        },
        editSrv(srv) {
            this.current=srv;
            this.showFormDlg = true;
        },
        deleteSrv(srv) {
            // Confirm and delete
            this.$confirm(`Να διαγραφεί η επιλεγμένη αίτηση?`, {title: "Διαγραφή αίτησης"})
            .then(res => {
                if (res) {
                    axios.delete(`/srvs/${srv.id}`, {
                        headers: {Authorization: this.$store.getters.authHeader}
                    }).then((response) => {
                        var idx = this.localSrvs.findIndex(x => x.id == srv.id);
                        this.localSrvs.splice(idx, 1);
                        EventBus.$emit('success-message', `Επιτυχής διαγραφή της αίτησης`);
                    }).catch(error => {
                        EventBus.$emit('error-message', 'Σφάλμα κατά τη διαγραφή της αίτησης');
                    });
                }
            });
        },
        onCreateSrv(srv) {
            this.showFormDlg = false;
            this.localSrvs.push(srv);
            EventBus.$emit('success-message', `Η αίτηση καταχωρήθηκε με επιτυχία`);
        },
        onUpdateSrv(srv) {
            this.showFormDlg = false;
            Object.assign(this.current, srv);
            EventBus.$emit('success-message', `Τα στοιχεία της αίτησης ενημερώθηκαν με επιτυχία`);
        },
        onError(error) {
            this.showFormDlg = false;
            EventBus.$emit('error-message', `Σφάλμα κατά την αποθήκευση της αίτησης`);
        },
        resetCurrent() {
            this.current = {
                id: null,
                client_id: this.client_id,
                csrv: null,
                apply: moment().format('YYYY-MM-DD'),
                notes: null
            }
        }
    }

}
</script>

<style>

</style>