<template>
    <div>
        <!-- Cdoc form dialog -->
        <template>
            <v-dialog max-width="600px" persistent v-model="showFormDlg">
                <cdoc-form 
                    :cdoc="current" 
                    @created="onCreateCdoc"
                    @updated="onUpdateCdoc" 
                    @error="onError"
                    @cancel="showFormDlg=false">
                </cdoc-form>
            </v-dialog>
        </template>

        <!-- Toolbar -->
        <v-toolbar flat color="white" class=mb-1>
            <v-toolbar-title class="text-subtitle-1">Απαιτούμενα έγγραφα</v-toolbar-title>
            <v-spacer></v-spacer>

            <!-- New cdoc button -->
            <v-btn color="primary" text dark class="mb-2" @click="createCdoc">
                <v-icon left>mdi-plus-circle-outline</v-icon>
                ΝΕΟ ΕΓΓΡΑΦΟ
            </v-btn>

            <!-- Refresh button -->
            <v-btn color="warning" text dark class="mb-2" @click="fetch">
                <v-icon left>mdi-replay</v-icon>
                ΑΝΑΝΕΩΣΗ
            </v-btn>

        </v-toolbar>

        <!-- Datatable -->
        <v-data-table dense
            :headers="headers"
            :items = "csrv.cdocs"
            item-key="csrv.cdocs.id"
            :loading="loading"
            must-sort
            :sort-by.sync="options.sortBy"
            :sort-desc.sync="options.sortDesc"
            :items-per-page.sync="options.itemsPerPage"
            :hide-default-footer="true"
            no-data-text="Δεν υπάρχουν καταχωρημένα έγγραφα"
            >
            <template v-slot:item.actions="{item}">
                <div class="layout">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                class="mr-2"
                                @click="editCdoc(item)"
                                color="blue"
                                v-on="on">
                                mdi-lead-pencil
                            </v-icon>
                        </template>
                        <span>Επεξεργασία εγγράφου</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                class="mr-2"
                                @click="deleteCdoc(item)"
                                color="red"
                                v-on="on">
                                mdi-delete
                            </v-icon>
                        </template>
                        <span>Διαγραφή εγγράφου</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                class="mr-2"
                                @click="moveUp(item)"
                                :disabled="item.aa == 1"
                                color="#00796B"
                                v-on="on">
                                mdi-sort-bool-descending
                            </v-icon>
                        </template>
                        <span>Μετακίνηση επάνω</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                class="mr-2"
                                @click="moveDown(item)"
                                :disabled="item.aa == maxAA"
                                color="#00796B"
                                v-on="on">
                                mdi-sort-bool-ascending
                            </v-icon>
                        </template>
                        <span>Μετακίνηση Κάτω</span>
                    </v-tooltip>
                </div>
            </template>

        </v-data-table>

    </div>
</template>

<script>
import axios from '@/plugins/axios'
import { EventBus } from '@/app.js'
import CdocForm from './Form'

export default {
    props: {
        csrv_id: {
            required: true
        }
    },
    components: {
        'cdoc-form': CdocForm,
    },
    created() {
        this.fetch();
    },
    computed: {
        headers() {
            return [
                {text: "Αύξ. αριθ.", value: "aa", sortable: false},
                {text: "Περιγραφή Εγγράφου", value: "descr", sortable: false},
                {text: "Ενέργειες", value: "actions", sortable: false}
            ]
        },
        maxAA() {
            var maxAa;
            if (!this.csrv) maxAa = 0;
            else maxAa = Math.max(...this.csrv.cdocs.map(d => d.aa), 0);
            return maxAa;
        },
        numberOfCdocs() {
            if (this.csrv.cdocs) return this.csrv.cdocs.length;
            else return 0;        
        }
    },
    watch: {
        numberOfCdocs(val) {
            this.$emit('count', val);
        }
    },
    data() {
        return {
            csrv: {},
            options: {
                sortBy: 'aa',
                sortDesc: false,
                itemsPerPage: -1,
            },
            loading: false,
            showFormDlg: false,
            current: {},
        }
    },
    methods: {
        fetch() {
            this.loading = true;
            axios.get(`/csrvs/${this.csrv_id}`, {
                headers: {Authorization: this.$store.getters.authHeader}
            }).then(response => {
                this.csrv = response.data;
                this.loading = false;
            }).catch(error => {
                this.loading = false;
                EventBus.$emit('error-message', 'Σφάλμα κατά την ανάκτηση των δεδομένων της υπηρεσίας')
            });
        },
        createCdoc() {
            this.resetCurrent();
            this.showFormDlg = true;
        },
        editCdoc(cdoc) {
            this.current = cdoc;
            this.showFormDlg = true;
        },
        deleteCdoc(cdoc) {
            // Confirm and delete
            this.$confirm(`Να διαγραφεί το επιλεγμένο έγγραφο?`, {title: "Διαγραφή εγγράφου"})
            .then(res => {
                if (res) {
                    this.loading = true;
                    axios.delete(`/cdocs/${cdoc.id}`, {
                        headers: {Authorization: this.$store.getters.authHeader}
                    }).then((response) => {
                        this.fetch();
                        this.loading = false;
                        EventBus.$emit('success-message', `Επιτυχής διαγραφή του εγγράφου`);
                    }).catch(error => {
                        this.loading = false;
                        EventBus.$emit('error-message', error.response.data.message);
                    });
                }
            });
        },
        onCreateCdoc(cdoc) {
            this.showFormDlg=false;
            this.csrv.cdocs.push(cdoc);
            EventBus.$emit('success-message', `Το έγγραφο καταχωρήθηκε με επιτυχία`);
        },
        onUpdateCdoc(cdoc) {
            this.showFormDlg = false;
            Object.assign(this.current, cdoc);
            EventBus.$emit('success-message', `Τα στοιχεία του εγγράφου ενημερώθηκαν με επιτυχία`);
        },
        onError(error) {
            this.showFormDlg = false;
            EventBus.$emit('error-message', `Σφάλμα κατά την αποθήκευση του εγγράφου`);
        },
        moveUp(cdoc) {
            if (cdoc.aa > 1) {
                this.loading = true;
                axios.post(`/cdocs/${cdoc.id}/moveup`, {}, {headers: {Authorization: this.$store.getters.authHeader}})
                .then(response => {
                    this.fetch();
                    this.loading = false;
                });
            }
        },
        moveDown(cdoc) {
            if (cdoc.aa < this.maxAA) {
                this.loading = true;
                axios.post(`/cdocs/${cdoc.id}/movedown`, {}, {headers: {Authorization: this.$store.getters.authHeader}})
                .then(response => {
                    this.fetch();
                    this.loading = false;
                });
            }
        },
        resetCurrent() {
            this.current = {
                id: null,
                csrv_id: this.csrv_id,
                aa: null,
                descr: null,
            }
        },
    }

    
}
</script>

<style>

</style>