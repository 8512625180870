<template>
    <div>
        <v-card>
            <v-card-title>
                <span class="headline">{{formTitle}}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-form ref="form" @keydown.native.enter.prevent="onSave">
                    <v-layout column>
                        <v-flex xs12>
                            <v-text-field
                                label="Όνομα χρήστη (*)"
                                v-model="localUser.name"
                                :rules="inputRules.name">
                            </v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field
                                label="Email (*)"
                                v-model="localUser.email"
                                :rules="inputRules.email">
                            </v-text-field>
                        </v-flex>
                        <v-flex xs12 v-if="!localUser.id">
                            <v-text-field
                                label="Κωδικός πρόσβασης (*)"
                                v-model="localUser.password"
                                append-icon="mdi-cached"
                                @click:append="generatePassword"
                                :rules="inputRules.password">
                            </v-text-field>
                        </v-flex>
                        <v-flex xs12 v-if="$store.getters.user.id != localUser.id">
                            <v-checkbox
                                label="Διαχειριστής"
                                v-model="localUser.admin">
                            </v-checkbox>
                        </v-flex>
                        <v-flex xs12 v-if="$store.getters.user.id != localUser.id">
                            <v-switch
                                label="Ενεργός"
                                v-model="localUser.active">
                            </v-switch>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions>  
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text :loading="loading" @click="onSave">ΑΠΟΘΗΚΕΥΣΗ</v-btn>
                <v-btn color="red darken-1" text @click="onCancel">ΑΚΥΡΩΣΗ</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import axios from '@/plugins/axios'
import generator from 'password-generator';

export default {
    props: {
        user: {
            required: true
        }
    },
    mounted() {
        Object.assign(this.localUser, this.user);
        this.$refs.form.resetValidation();
    },
    watch: {
        user(newVal, oldVal) {
            Object.assign(this.localUser, this.user);
            this.$refs.form.resetValidation();
        },
    },
    computed: {
        formTitle() {
            if (!this.localUser.id) {
                return "Καταχώρηση νέου χρήστη";
            }
            else {
                return "Επεξεργασία χρήστη";
            }
        },
    },
    data() {
        return {
            localUser: {
                id: null,
                name: null,
                email: null,
                password: null,
                active: null,
                admin: null,
                token: null,
                token_id: null,
            },
            inputRules: {
                name: [
                    v => !!v || "Πληκτρολογείστε το όνομα χρήστη",
                ],
                password: [
                    v => !!v || "Πληκτρολογείστε τον κωδικό πρόσβασης",
                ],
                email: [
                    v => !!v || "Πληκτρολογείστε το email του χρήστη",
                    v => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(v) || 'Μη έγκυρη διεύθυνση e-mail'
                    }
                ],
            },
            loading: false,
        }
    },
    methods: {
        onSave() {
            if (!this.$refs.form.validate()) return;
            this.$refs.form.resetValidation();
            var newUser = this.localUser;
            if (!newUser.id) this.create(newUser);
            else this.update(newUser);
        },
        onCancel() {
            this.$refs.form.resetValidation();
            this.$emit('cancel');
        },
        create(newUser) {
            this.loading = true;
            axios.post('/users', newUser, {headers: {Authorization: this.$store.getters.authHeader}})
            .then(response => {
                this.loading = false;
                this.$emit('created', response.data);
            }).catch(error => {
                this.loading = false;
                this.$emit('error', error);
            });
        },
        update(newUser) {
            this.loading = true;
            axios.put(`/users/${newUser.id}`, newUser, {headers: {Authorization: this.$store.getters.authHeader}})
            .then(response => {
                this.loading = false;
                this.$emit('updated', response.data);
            }).catch(error => {
                this.loading = false;
                this.$emit('error', error);
            });
        },
        generatePassword() {
            this.localUser.password = generator(8, false);
        },

    },

}
</script>

<style>

</style>