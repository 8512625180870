<template>
    <div>
        <v-row>
            <v-col cols="12" md="6">
                <v-card height="100%">
                    <v-card-title>Ατομικά Στοιχεία</v-card-title>
                    <v-card-text>
                        <!-- Ατομικά στοιχεία -->
                        <v-def label="Επωνυμία" :data="client.lastname"></v-def>
                        <v-def label="Ονομα" :data="client.firstname"></v-def>
                        <v-def label="Πατρώνυμο" :data="client.fathername"></v-def>
                        <v-def label="Μητρώνυμο" :data="client.mothername"></v-def>
                        <v-def label="Φύλο" :data="client.sex_descr"></v-def>
                        <v-def label="Ημ/νία γέννησης" :data="birthdate"></v-def>
                        <v-def label="Εθνικότητα" :data="client.nationality"></v-def>
                        <v-def label="Α.Δ.Τ." :data="client.adt"></v-def>
                        <v-def label="Α.Φ.Μ." :data="client.afm"></v-def>
                        <v-def label="Α.Μ.Κ.Α." :data="client.amka"></v-def>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <v-card height="100%">
                    <v-card-title>Στοιχεία Επικοινωνίας</v-card-title>
                    <v-card-text>
                        <!-- Στοιχεία επικοινωνίας -->
                        <v-def label="Πόλη" :data="client.city"></v-def>
                        <v-def label="Περιοχή" :data="client.area"></v-def>
                        <v-def label="Διεύθυνση" :data="client.address"></v-def>
                        <v-def label="Ταχ. κώδικας" :data="client.tk"></v-def>
                        <v-def label="E-mail" :data="client.email"></v-def>
                        <v-def label="Σταθερό τηλ." :data="client.phone"></v-def>
                        <v-def label="Κινητό" :data="client.mobil"></v-def>
                        <v-def label="Φαξ" :data="client.fax"></v-def>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import DefinitionItem from '@/components/DefinitionItem'

export default {
    props: {
        client: {
            required: true
        }
    },
    computed: {
        birthdate() {
            if (this.client && this.client.birthdate) {
                return this.$moment(this.client.birthdate).format('DD/MM/YYYY');
            }
            else {
                return '';
            }
        }
    },
    components: {
        'v-def': DefinitionItem,
    }    
}
</script>

<style>
.dl-horizontal dt {
    float: left;
    width: 160px;
    clear: left;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

</style>