<template>
    <div>
        <v-card>
            <v-card-title>
                <span class="headline">{{formTitle}}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-form ref="form" @keydown.native.enter.prevent="onSave">
                    <v-layout column>
                        <v-flex xs12>
                            <v-text-field
                                label="Περιγραφή (*)"
                                v-model="localCsrv.descr"
                                :rules="inputRules.descr">
                            </v-text-field>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions>  
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text :loading="loading" @click="onSave">ΑΠΟΘΗΚΕΥΣΗ</v-btn>
                <v-btn color="red darken-1" text @click="onCancel">ΑΚΥΡΩΣΗ</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import axios from '@/plugins/axios'

export default {
    props: {
        csrv: {
            required: true
        }
    },
    mounted() {
        Object.assign(this.localCsrv, this.csrv);
        this.$refs.form.resetValidation();
    },
    watch: {
        srv(newVal, oldVal) {
            Object.assign(this.localCsrv, this.csrv);
            this.$refs.form.resetValidation();
        },
    },
    computed: {
        formTitle() {
            if (!this.localCsrv.id) {
                return "Καταχώρηση νέας υπηρεσίας";
            }
            else {
                return "Επεξεργασία υπηρεσίας";
            }
        },
    },
    data() {
        return {
            localCsrv: {
                id: null,
                descr: null,
                cdocs_n: null,
                srvs_n: null,
            },
            inputRules: {
                descr: [
                    v => !!v || "Πληκτρολογείστε την περιγραφή της υπηρεσίας",
                ],
            },
            loading: false,
        }
    },
    methods: {
        onSave() {
            if (!this.$refs.form.validate()) return;
            this.$refs.form.resetValidation();
            var newCsrv = {
                id: this.localCsrv.id,
                descr: this.localCsrv.descr
            }
            if (!newCsrv.id) this.create(newCsrv);
            else this.update(newCsrv);
        },
        onCancel() {
            this.$refs.form.resetValidation();
            this.$emit('cancel');
        },
        create(newCsrv) {
            this.loading = true;
            axios.post('/csrvs', newCsrv, {headers: {Authorization: this.$store.getters.authHeader}})
            .then(response => {
                this.loading = false;
                this.$emit('created', response.data);
            }).catch(error => {
                this.loading = false;
                this.$emit('error', error);
            });
        },
        update(newCsrv) {
            this.loading = true;
            axios.put(`/csrvs/${newCsrv.id}`, newCsrv, {headers: {Authorization: this.$store.getters.authHeader}})
            .then(response => {
                this.loading = false;
                this.$emit('updated', response.data);
            }).catch(error => {
                this.loading = false;
                this.$emit('error', error);
            });
        }

    },

}
</script>

<style>

</style>