import Vue from 'vue'
import vuetify from './plugins/vuetify'
import {store} from './store'
import router from './router'
import vueMoment from 'vue-moment'
import VuetifyConfirm from 'vuetify-confirm'
import wysiwyg from "vue-wysiwyg"

require('./bootstrap');

window.Vue = require('vue');

// Vue components
Vue.component('app-component', require('./components/App.vue').default);

// Event Bus
export const EventBus = new Vue();

Vue.use(vueMoment);
Vue.use(VuetifyConfirm, {
    vuetify,
    buttonTrueText: 'ΑΠΟΔΟΧΗ',
    buttonFalseText: 'ΑΚΥΡΩΣΗ',
    color: 'error',
    icon: 'mdi-alert',
    title: '',
    width: 350,
    property: '$confirm'
  });

// WYSIWYG editor
Vue.use(wysiwyg, {

});

// Vue App
const app = new Vue({
    el: '#app',
    vuetify,
    store,
    router
});
