var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[[_c('v-dialog',{attrs:{"max-width":"600px","persistent":""},model:{value:(_vm.showFormDlg),callback:function ($$v) {_vm.showFormDlg=$$v},expression:"showFormDlg"}},[_c('srv-form',{attrs:{"srv":_vm.current},on:{"created":_vm.onCreateSrv,"updated":_vm.onUpdateSrv,"cancel":function($event){_vm.showFormDlg=false}}})],1)],_vm._v(" "),_c('v-card',{attrs:{"height":"100%"}},[_c('v-card-title',[_vm._v("\n            Αιτήσεις\n            "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","text":"","dark":""},on:{"click":_vm.createSrv}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-circle-outline")]),_vm._v("\n                ΝΕΑ ΑΙΤΗΣΗ\n            ")],1)],1),_vm._v(" "),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.localSrvs,"items-per-page":_vm.options.itemsPerPage,"no-data-text":"Δεν υπάρχουν αιτήσεις","footer-props":{
                    'items-per-page-text': 'Εγγραφές ανά σελίδα'
                },"expanded":_vm.expanded,"show-expand":""},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.options, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.options, "itemsPerPage", $event)},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
                var headers = ref.headers;
                var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('docs-list',{staticClass:"my-4",attrs:{"docs":item.docs,"srv_id":item.id}})],1)]}},{key:"item.apply",fn:function(ref){
                var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("moment")(item.apply,"DD/MM/YYYY")))])]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"layout"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"blue"},on:{"click":function($event){return _vm.editSrv(item)}}},on),[_vm._v("\n                                    mdi-lead-pencil\n                                ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Επεξεργασία αίτησης")])]),_vm._v(" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteSrv(item)}}},on),[_vm._v("\n                                    mdi-delete\n                                ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Διαγραφή αίτησης")])])],1)]}}])})],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }