<template>
    <div>
        <div v-if="loading" class="text-center mt-10">
            <v-progress-circular
                indeterminate
                color="primary"
                :size="48">
            </v-progress-circular>
        </div>

        <div v-if="!loading">
            <v-toolbar flat color="white" class=mb-1>
                <v-toolbar-title>Επεξεργασία Επαφής</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>

            <client-form
                :client="client"
                @updated="onUpdate"
                @error="onError"
                @cancel="onCancel">
            </client-form>
        </div>
    </div>
</template>

<script>
import axios from '@/plugins/axios'
import { EventBus } from '@/app.js'
import ClientForm from './Form'


export default {
        props: {
        client_id: {
            required: true
        }
    },
    components: {
       'client-form': ClientForm,
    },
    created() {
        this.fetch();
    },
    data() {
        return {
            client: {},
            loading: false,
        }
    },
    methods: {
        fetch() {
            this.loading = true;
            axios.get(`/clients/${this.client_id}`, {
                headers: {Authorization: this.$store.getters.authHeader}
            }).then(response => {
                this.client = response.data;
                this.loading = false;
            }).catch(error => {
                this.loading = false;
                EventBus.$emit('error-message', 'Σφάλμα κατά την ανάκτηση των δεδομένων της επαφής')
            });
        },
        onUpdate(newClient) {
            EventBus.$emit('success-message', 'Τα στοιχεία της επαφής ενημερώθηκαν με επιτυχία')
            this.$router.push({name: 'clientShow', params:{client_id: newClient.id}});
        },
        onCancel() {
            this.$router.go(-1);
        },
        onError(error) {
            EventBus.$emit('success-message', 'Σφάλμα κατά την ενημέρωση των στοιχείων της επαφής')
            this.$router.push({name: 'clientShow', params:{client_id: newClient.id}});
        }
    }
}
</script>

<style>

</style>