<template>
  <div>
        <div v-if="loading" class="text-center mt-10">
            <v-progress-circular
                indeterminate
                color="primary"
                :size="48">
            </v-progress-circular>
        </div>
      
      <div v-if="!loading">
        <v-toolbar flat color="white" class=mb-1>
            <v-toolbar-title>{{client.lastname}} {{client.firstname}}</v-toolbar-title>
            <v-spacer></v-spacer>

            <!-- Go back button -->
            <v-btn color="success" text dark class="mb-2" @click="goToClientList">
                <v-icon left>mdi-table-account</v-icon>
                ΛΙΣΤΑ ΕΠΑΦΩΝ
            </v-btn>
        </v-toolbar>

            <v-card>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="3">
                            <side-bar :client=client></side-bar>
                        </v-col>
                        <v-col cols=12 md="9">
                            <show-personal-data :client="client"></show-personal-data>
                            <edu-list :edus="client.edus" :client_id="client.id"></edu-list>
                            <srv-list :srvs="client.srvs" :client_id="client.id"></srv-list>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </div>
  </div>
</template>

<script>
import axios from '@/plugins/axios'
import { EventBus } from '@/app.js'
import SideBar from './ShowSideBar'
import ShowPersonalData from './ShowPersonalData'
import EduList from '@/components/Edus/List'
import SrvList from '@/components/Srvs/List'

export default {
    components: {
        'side-bar': SideBar,
        'show-personal-data': ShowPersonalData,
        'edu-list': EduList,
        'srv-list': SrvList,
    },
    props: {
        client_id: {
            required: true
        }
    },
    data() {
        return {
            client:{},
            loading: false,
        }
    },
    computed: {
        
    },
    created() {
        this.fetch();
    },
    methods: {
        fetch() {
            this.loading = true;
            axios.get(`/clients/${this.client_id}`, {
                headers: {Authorization: this.$store.getters.authHeader}
            }).then(response => {
                this.client = response.data;
                this.loading = false;
            }).catch(error => {
                this.loading = false;
                EventBus.$emit('error-message', 'Σφάλμα κατά την ανάκτηση των δεδομένων της επαφής')
            });
        },
        goToClientList() {
            this.$router.push({name: 'clientList'});
        }
    }

}
</script>

<style>

</style>