var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[[_c('v-dialog',{attrs:{"max-width":"600px","persistent":""},model:{value:(_vm.showFormDlg),callback:function ($$v) {_vm.showFormDlg=$$v},expression:"showFormDlg"}},[_c('user-form',{attrs:{"user":_vm.current},on:{"created":_vm.onCreateUser,"updated":_vm.onUpdateUser,"error":_vm.onError,"cancel":function($event){_vm.showFormDlg=false}}})],1)],_vm._v(" "),_c('reset-password-dlg',{attrs:{"show":_vm.showResetPasswordDlg,"user":_vm.current},on:{"cancel":_vm.onResetPasswordCancel,"success":_vm.onResetPasswordSuccess,"error":_vm.onResetPasswordError}}),_vm._v(" "),_c('v-toolbar',{staticClass:"mb-1",attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Χρήστες Εφαρμογής")]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","text":"","dark":""},on:{"click":_vm.createUser}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-circle-outline")]),_vm._v("\n            ΝΕΟΣ ΧΡΗΣΤΗΣ\n        ")],1),_vm._v(" "),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"warning","text":"","dark":""},on:{"click":_vm.fetch}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-replay")]),_vm._v("\n            ΑΝΑΝΕΩΣΗ\n        ")],1)],1),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.users,"must-sort":"","loading":_vm.listLoading,"loadingText":"Ανάκτηση εγγραφών","sort-by":_vm.options.sortBy,"sort-desc":_vm.options.sortDesc,"items-per-page":_vm.options.itemsPerPage,"no-data-text":"Δεν υπάρχουν χρήστες","footer-props":{
            'items-per-page-text': 'Εγγραφές ανά σελίδα'
        }},on:{"update:sortBy":function($event){return _vm.$set(_vm.options, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.options, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.options, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.options, "sortDesc", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.options, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.options, "itemsPerPage", $event)}},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(item){return [_vm._v("\n            Εγγραφές "+_vm._s(item.pageStart)+" έως "+_vm._s(item.pageStop)+" από "+_vm._s(item.itemsLength)+"\n        ")]}},{key:"item.active",fn:function(ref){
        var item = ref.item;
return [(item.active==1)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")]):_vm._e(),_vm._v(" "),(!item.active==1)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-block-helper")]):_vm._e()]}},{key:"item.updated_at",fn:function(ref){
        var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("moment")(item.updated_at,"DD/MM/YYYY"))+", "+_vm._s(_vm._f("moment")(item.updated_at,"HH:mm")))])]}},{key:"item.admin",fn:function(ref){
        var item = ref.item;
return [_vm._v("\n            "+_vm._s(item.admin == 1 ? 'Διαχειριστής' : 'Απλός χρήστης')+"\n        ")]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"layout"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"blue"},on:{"click":function($event){return _vm.editUser(item)}}},on),[_vm._v("\n                            mdi-lead-pencil\n                        ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Επεξεργασία χρήστη")])]),_vm._v(" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteUser(item)}}},on),[_vm._v("\n                            mdi-delete\n                        ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Διαγραφή χρήστη")])]),_vm._v(" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"yellow darken-3"},on:{"click":function($event){return _vm.resetPassword(item)}}},on),[_vm._v("\n                            mdi-key\n                        ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Επαναφορά κωδικού")])])],1)]}}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }