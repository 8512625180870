import {API_BASE_URL, SNACKBAR_TIMEOUT} from '@/../../config.js'

const PARAMS = {
    
    // Base API Address
    API_BASE_URL: API_BASE_URL,

    // Snackbar timeout
    SNACKBAR_TIMEOUT: 3000,

};

export default PARAMS;