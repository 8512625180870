<template>
    <div>

        <!-- User form dialog -->
        <template>
            <v-dialog max-width="600px" persistent v-model="showFormDlg">
                <user-form 
                    :user="current" 
                    @created="onCreateUser"
                    @updated="onUpdateUser" 
                    @error="onError"
                    @cancel="showFormDlg=false">
                </user-form>
            </v-dialog>
        </template>

        <!-- Password reset dialog -->
        <reset-password-dlg 
            :show="showResetPasswordDlg"
            :user="current"
            @cancel="onResetPasswordCancel"
            @success="onResetPasswordSuccess"
            @error="onResetPasswordError">
        </reset-password-dlg>

        <v-toolbar flat color="white" class=mb-1>
            <v-toolbar-title>Χρήστες Εφαρμογής</v-toolbar-title>
            <v-spacer></v-spacer>
            
            <!-- New user button -->
            <v-btn color="primary" text dark class="mb-2" @click="createUser">
                <v-icon left>mdi-plus-circle-outline</v-icon>
                ΝΕΟΣ ΧΡΗΣΤΗΣ
            </v-btn>

            <!-- Refresh button -->
            <v-btn color="warning" text dark class="mb-2" @click="fetch">
                <v-icon left>mdi-replay</v-icon>
                ΑΝΑΝΕΩΣΗ
            </v-btn>
        </v-toolbar>

        

        <!-- Datatable -->
        <v-data-table
            :headers="headers"
            :items = "users"
            must-sort
            :loading="listLoading"
            loadingText="Ανάκτηση εγγραφών"
            :sort-by.sync="options.sortBy"
            :sort-desc.sync="options.sortDesc"
            :items-per-page.sync="options.itemsPerPage"
            no-data-text="Δεν υπάρχουν χρήστες"
            class="elevation-1"
            :footer-props="{
                'items-per-page-text': 'Εγγραφές ανά σελίδα'
            }"
        >
            <template slot="footer.page-text" slot-scope="item">
                Εγγραφές {{item.pageStart}} έως {{item.pageStop}} από {{item.itemsLength}}
            </template>
            <template v-slot:item.active="{item}">
                <v-icon v-if="item.active==1" color="green">mdi-check</v-icon>
                <v-icon v-if="!item.active==1" color="red">mdi-block-helper</v-icon>
            </template>
            <template v-slot:item.updated_at="{item}">
                <div>{{ item.updated_at | moment("DD/MM/YYYY") }}, {{ item.updated_at | moment("HH:mm") }}</div>
            </template>
            <template v-slot:item.admin="{item}">
                {{item.admin == 1 ? 'Διαχειριστής' : 'Απλός χρήστης'}}
            </template>
            <template v-slot:item.actions="{item}">
                <div class="layout">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                class="mr-2"
                                @click="editUser(item)"
                                color="blue"
                                v-on="on">
                                mdi-lead-pencil
                            </v-icon>
                        </template>
                        <span>Επεξεργασία χρήστη</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                class="mr-2"
                                @click="deleteUser(item)"
                                color="red"
                                v-on="on">
                                mdi-delete
                            </v-icon>
                        </template>
                        <span>Διαγραφή χρήστη</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                @click="resetPassword(item)"
                                color="yellow darken-3"
                                v-on="on">
                                mdi-key
                            </v-icon>
                        </template>
                        <span>Επαναφορά κωδικού</span>
                    </v-tooltip>
                </div>
            </template>

        </v-data-table>
    </div>
</template>

<script>
import axios from '@/plugins/axios'
import { EventBus } from '@/app.js'
import UserForm from './Form'
import ResetPasswordDlg from './ResetPasswordDlg'
import generator from 'password-generator';

export default {
    components: {
        'user-form': UserForm,
        'reset-password-dlg': ResetPasswordDlg,
    },
    created() {
        this.fetch();
    },
    computed: {
        headers() {
            return [
                {text: "Ενεργός", value: "active"},
                {text: "Ενημέρωση", value: "updated_at"},
                {text: "Όνομα", value: "name"},
                {text: "E-mail", value: "email"},
                {text: "Ρόλος", value: "admin"},
                {text: "Ενέργειες", value: "actions", sortable: false}
            ]
        },
    },
    data() {
        return {
            users: [],
            options: {
                sortBy: 'updated_at',
                sortDesc: true,
                itemsPerPage: 10,
            },
            loading: false,
            showResetPasswordDlg: false,
            showFormDlg: false,
            current: {},
        }
    },

    methods: {
        fetch() {
            this.listLoading = true;
            axios.get('/users', {
                headers: {Authorization: this.$store.getters.authHeader}
            }).then(response => {
                this.users = response.data;
                this.listLoading = false;
            }).catch(error => {
                this.listLoading = false;
                EventBus.$emit('error-message', 'Σφάλμα κατά την ανάκτηση των χρηστών')
            });
        },
        createUser() {
            this.resetCurrent();
            this.current.active = true;
            this.showFormDlg = true;
        },
        editUser(user) {
            this.current = user;
            this.showFormDlg = true;
        },
        deleteUser(user) {
            // Confirm and delete
            this.$confirm(`Να διαγραφεί ο χρήστης "${user.name}"`, {title: "Διαγραφή χρήστη"})
            .then(res => {
                if (res) {
                    axios.delete(`/users/${user.id}`, {
                        headers: {Authorization: this.$store.getters.authHeader}
                    }).then((response) => {
                        var index = this.users.findIndex(u => u.id == user.id);
                        this.users.splice(index, 1);
                        EventBus.$emit('success-message', `Επιτυχής διαγραφή του χρήστη ${user.name}`);
                    }).catch(error => {
                        EventBus.$emit('error-message', error.response.data.message);
                    });
                }
            });
        },
        onCreateUser(user) {
            this.showFormDlg=false;
            this.users.push(user);
            EventBus.$emit('success-message', `Ο χρήστης δημιουργήθηκε με επιτυχία`);
        },
        onUpdateUser(user) {
            this.showFormDlg = false;
            Object.assign(this.current, user);
            // if user is the connected user logout
            if (this.$store.getters.user.id == user.id) {
                this.$router.push({name: 'logout'});
                EventBus.$emit('success-message', 'Τα στοιχεία του συνδεδεμένου χρήστη άλλαξαν με επιτυχία. Παρακαλώ συνδεθείτε ξανά');
            }
            else {
                EventBus.$emit('success-message', `Τα στοιχεία του χρήστη '${user.name}' ενημερώθηκαν με επιτυχία`);
            }
        },
        onError(error) {
            this.showFormDlg = false;
            EventBus.$emit('error-message', `Σφάλμα κατά την αποθήκευση του χρήστη`);
        },
        resetCurrent() {
            this.current = {
                id: null,
                name: null,
                email: null,
                password: null,
                active: null,
                admin: null,
                token: null,
                token_id: null
            }
        },
        resetPassword(user) {
            this.current = user;
            this.showResetPasswordDlg = true;
        },
        onResetPasswordError(error) {
            EventBus.$emit('error-message', error);
            this.showResetPasswordDlg = false;
        },
        onResetPasswordSuccess(user) {
            this.showResetPasswordDlg = false;
            // if user is the connected user logout
            if (this.$store.getters.user.id == user.id) {
                this.$store.dispatch('setUser', user);
                this.$router.push({name: 'logout'});
                EventBus.$emit('success-message', 'Παρακαλώ συνδεθείτε με τον νέο κωδικό πρόσβασης');
            }
            else {
                EventBus.$emit('success-message', `Ο κωδικός πρόσβασης του χρήστη '${user.name}' άλλαξε με επιτυχία`);
            }
        },
        onResetPasswordCancel() {
            this.showResetPasswordDlg = false;
        },
    }
}
</script>

<style>

</style>