<template>
    <div>
        <v-card>
            <v-card-title>
                <span class="headline">{{formTitle}}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-form ref="form" @keydown.native.enter.prevent="onSave">
                    <v-layout column>
                        <v-flex xs12>
                            <v-textarea
                                label="Περιγραφή Εγγράφου (*)"
                                v-model="localDoc.descr"
                                :rules="inputRules.descr">
                            </v-textarea>
                        </v-flex>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-checkbox
                                    v-model="localDoc.done"
                                    label="Διεκπαιρεθώθηκε"
                                    color="success"
                                    hide-details>
                                </v-checkbox>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-menu
                                    ref="doneAtMenu"
                                    v-model="doneAtMenu"
                                    :return-value.sync="doneAtComputed"
                                    transition="scale-transition"
                                    :close-on-content-click="false"
                                    offset-y
                                    min-width="290px"
                                    :disabled="!localDoc.done"
                                > 
                                    <template v-slot:activator="{on, attrs}">
                                        <v-text-field
                                            v-model="doneAtFormatted"
                                            label="Ημ/νία διεκπαιρέωσης"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            :disabled="!localDoc.done"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker 
                                        ref="doneAtPicker"
                                        v-model="doneAtComputed" 
                                        locale="el"
                                        :first-day-of-week="1"
                                        @change="saveDoneAt"
                                    >
                                    </v-date-picker>
                            </v-menu>
                            </v-col>
                        </v-row>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions>  
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text :loading="loading" @click="onSave">ΑΠΟΘΗΚΕΥΣΗ</v-btn>
                <v-btn color="red darken-1" text @click="onCancel">ΑΚΥΡΩΣΗ</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import axios from '@/plugins/axios'
import { EventBus } from '@/app.js'
import { formatDate } from '@/util'
import moment from 'moment'

export default {
    props: {
        doc: {
            required: true
        }
    },
    created() {
        
    },
    mounted() {
        Object.assign(this.localDoc, this.doc);
        this.$refs.form.resetValidation();
    },
    watch: {
        doc(newVal, oldVal) {
            Object.assign(this.localDoc, this.doc);
            this.$refs.form.resetValidation();
        },
        doneComputed(val) {
            if (val==0) {
                this.localDoc.done_at = null;
            }
            else if (val==1 && !this.localDoc.done_at) {
                this.localDoc.done_at = moment().format('YYYY-MM-DD');
            }
        }   
    },
    computed: {
        formTitle() {
            if (!this.localDoc.id) {
                return "Καταχώρηση νέου εγγράφου";
            }
            else {
                return "Επεξεργασία εγγράφου";
            }
        },
        doneComputed() {
            return this.localDoc.done;
        },
        doneAtFormatted() {
            return formatDate(this.localDoc.done_at);
        },
        doneAtComputed: {
            set(date) {
                if (date) this.localDoc.done_at = moment(date).format("YYYY-MM-DD");
                else this.localDoc.done_at = null;
            },
            get() {
                if (this.localDoc.done_at) return moment(this.localDoc.done_at).format("YYYY-MM-DD");
                else return null;
            }
        }
    },
    data() {
        return {
            localDoc: {
                id: null,
                srv_id: null,
                aa: null,
                descr: null,
                notes: null,
                docfile: null,
                done: null,
                done_at: null
            },
            inputRules: {
                descr: [
                    v => !!v || "Πληκτρολογείστε την περιγραφή του εγγράφου",
                ],
            },
            loading: false,
            doneAtMenu: false,
        }
    },
    methods: {
        saveDoneAt(date) {
            this.$refs.doneAtMenu.save(date);
        },
        onSave() {
            if (!this.$refs.form.validate()) return;
            this.$refs.form.resetValidation();
            var newDoc = this.localDoc;
            if (!newDoc.id) this.create(newDoc);
            else this.update(newDoc);
        },
        onCancel() {
            this.$refs.form.resetValidation();
            this.$emit('cancel');
        },
        create(newDoc) {
            this.loading = true;
            axios.post('/docs', newDoc, {headers: {Authorization: this.$store.getters.authHeader}})
            .then(response => {
                this.loading = false;
                this.$emit('created', response.data);
            }).catch(error => {
                this.loading = false;
                this.$emit('error', error);
            });
        },
        update(newDoc) {
            this.loading = true;
            axios.put(`/docs/${newDoc.id}`, newDoc, {headers: {Authorization: this.$store.getters.authHeader}})
            .then(response => {
                this.loading = false;
                this.$emit('updated', response.data);
            }).catch(error => {
                this.loading = false;
                this.$emit('error', error);
            });
        }

    },

}
</script>

<style>

</style>