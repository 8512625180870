<template>
    <div>
        <!-- Doc form dialog -->
        <template>
            <v-dialog max-width="600px" persistent v-model="showFormDlg">
                <doc-form 
                    :doc="current" 
                    @created="onCreateDoc"
                    @updated="onUpdateDoc" 
                    @error="onError"
                    @cancel="showFormDlg=false">
                </doc-form>
            </v-dialog>
        </template>

        <!-- Notes dialog -->
        <notes-dialog
            v-model="showNotesDlg"
            :url="notesUrl"
            :notes="editedNotes"
            @save="onSaveNotes"
            @cancel="onCancelNotes">
        </notes-dialog>

        <!-- upload file dialog -->
        <template>
            <v-dialog max-width="600px" persistent v-model="showUploadFileDlg">
                <upload-file
                    label="Αρχείο εγγράφου" 
                    fieldName="docfile"
                    :url="uploadDocfileUrl"
                    @uploaded="onUploadDocfile"
                    @cancel="showUploadFileDlg=false">
                </upload-file>
            </v-dialog>
        </template>

        <!-- Toolbar -->
        <v-toolbar flat color="white" class=mb-1>
            <v-toolbar-title class="text-subtitle-1">Εγγραφα</v-toolbar-title>
            <v-spacer></v-spacer>

            <!-- New doc button -->
            <v-btn color="primary" text dark class="mb-2" @click="createDoc">
                <v-icon left>mdi-plus-circle-outline</v-icon>
                ΝΕΟ ΕΓΓΡΑΦΟ
            </v-btn>

            <!-- Refresh button -->
            <v-btn color="warning" text dark class="mb-2" @click="fetch">
                <v-icon left>mdi-replay</v-icon>
                ΑΝΑΝΕΩΣΗ
            </v-btn>

        </v-toolbar>

        <!-- Datatable -->
        <v-data-table dense
            :headers="headers"
            :items = "docs"
            item-key="id"
            :loading="loading"
            must-sort
            :sort-by.sync="options.sortBy"
            :sort-desc.sync="options.sortDesc"
            :items-per-page.sync="options.itemsPerPage"
            :hide-default-footer="true"
            no-data-text="Δεν υπάρχουν καταχωρημένα έγγραφα"
            >
            <template v-slot:item.done="{item}">
                <v-icon
                    v-if="item.done == 1"
                    color="success">
                    mdi-check-bold
                </v-icon>
                <v-icon
                    v-if="item.done != 1"
                    color="warning">
                    mdi-alert-outline
                </v-icon>
            </template>
            <template v-slot:item.done_at="{item}">
                <div v-if="item.done_at">{{item.done_at | moment("DD/MM/YYYY")}}</div>
                <div v-if="!item.done_at"></div>
            </template>
            <template v-slot:item.notes="{item}">
                <div class="text-left">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                @click="editNotes(item)"
                                v-on="on"
                                :color="item.notes ? 'success' : ''">
                                mdi-grease-pencil
                            </v-icon>
                        </template>
                        <span>Προβολή Σημειώσεων</span>
                    </v-tooltip>
                </div>
            </template>
            <template v-slot:item.docfile="{item}">
                <div class="text-left">
                    <v-tooltip top v-if="item.docfile">
                        <template v-slot:activator="{ on }">
                            <v-icon
                                @click="viewDocfile(item)"
                                v-on="on">
                                mdi-file-document-outline
                            </v-icon>
                        </template>
                        <span>Προβολή αρχείου</span>
                    </v-tooltip>
                </div>
            </template>
            <template v-slot:item.actions="{item}">
                <div class="layout">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                class="mr-2"
                                @click="editDoc(item)"
                                color="blue"
                                v-on="on">
                                mdi-lead-pencil
                            </v-icon>
                        </template>
                        <span>Επεξεργασία εγγράφου</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                class="mr-2"
                                @click="deleteDoc(item)"
                                color="red"
                                v-on="on">
                                mdi-delete
                            </v-icon>
                        </template>
                        <span>Διαγραφή εγγράφου</span>
                    </v-tooltip>
                    <v-tooltip top v-if="item.docfile">
                                <template v-slot:activator="{ on }">
                                    <v-icon
                                        class="mr-2"
                                        @click="deleteDocfile(item)"
                                        color="orange"
                                        v-on="on">
                                        mdi-file-remove-outline
                                    </v-icon>
                                </template>
                                <span>Διαγραφή αρχείου</span>
                            </v-tooltip>
                            <v-tooltip top v-if="!item.docfile">
                                <template v-slot:activator="{ on }">
                                    <v-icon
                                        class="mr-2"
                                        @click="uploadDocfile(item)"
                                        color="green"
                                        v-on="on">
                                        mdi-file-plus-outline
                                    </v-icon>
                                </template>
                                <span>Μεταφόρτωση αρχείου</span>
                            </v-tooltip>
                </div>
            </template>

        </v-data-table>

    </div>
</template>

<script>
import axios from '@/plugins/axios'
import { EventBus } from '@/app.js'
import DocForm from './Form'
import { formatDate } from '@/util'
import UploadFile from '@/components/UploadFile'
import NotesDialog from '@/components/NotesDialog'

export default {
    props: {
        srv_id: {
            required: true
        }
    },
    components: {
        'doc-form': DocForm,
        'upload-file': UploadFile,
        'notes-dialog': NotesDialog,
    },
    created() {
        this.fetch();
    },
    computed: {
        headers() {
            return [
                {text: "", value: "done", sortable: false},
                {text: "Διεκ/ση", value: "done_at", sortable: false},
                {text: "Περιγραφή Εγγράφου", value: "descr", sortable: false},
                {text: "Σημειώσεις", value: "notes", sortable: false},
                {text: "Αρχείο", value: "docfile", sortable: false},
                {text: "Ενέργειες", value: "actions", sortable: false},
            ]
        },
        maxAA() {
            var maxAa;
            if (!this.docs) maxAa = 0;
            else maxAa = Math.max(...this.docs.map(d => d.aa), 0);
            return maxAa;
        },
    },
    data() {
        return {
            docs: [],
            options: {
                sortBy: 'aa',
                itemsPerPage: -1,
            },
            loading: false,
            showFormDlg: false,
            current: {},
            showUploadFileDlg: false,
            uploadDocfileUrl: '',
            showNotesDlg: false,
            editedNotes: "",
            notesUrl: "",
        }
    },
    methods: {
        fetch() {
            this.loading = true;
            axios.get(`/srvs/${this.srv_id}/docs`, {
                headers: {Authorization: this.$store.getters.authHeader}
            }).then(response => {
                this.docs = response.data;
                this.loading = false;
            }).catch(error => {
                this.loading = false;
                EventBus.$emit('error-message', 'Σφάλμα κατά την ανάκτηση των εγγράφων')
            });
        },
        createDoc() {
            this.resetCurrent();
            this.showFormDlg = true;
        },
        editDoc(doc) {
            this.current = doc;
            this.showFormDlg = true;
        },
        deleteDoc(doc) {
            // Confirm and delete
            this.$confirm(`Να διαγραφεί το επιλεγμένο έγγραφο?`, {title: "Διαγραφή εγγράφου"})
            .then(res => {
                if (res) {
                    this.loading = true;
                    axios.delete(`/docs/${doc.id}`, {
                        headers: {Authorization: this.$store.getters.authHeader}
                    }).then((response) => {
                        this.fetch();
                        this.loading = false;
                        EventBus.$emit('success-message', `Επιτυχής διαγραφή του εγγράφου`);
                    }).catch(error => {
                        this.locading = false;
                        EventBus.$emit('error-message', 'Σφάλμα κατά τη διαγραφή του εγγράφου');
                    });
                }
            });
        },
        onCreateDoc(doc) {
            this.showFormDlg = false;
            this.docs.push(doc);
            EventBus.$emit('success-message', `Το έγγραφο καταχωρήθηκε με επιτυχία`);
        },
        onUpdateDoc(doc) {
            this.showFormDlg = false;
            Object.assign(this.current, doc);
            EventBus.$emit('success-message', `Τα στοιχεία του εγγράφου ενημερώθηκαν με επιτυχία`);
        },
        onError(error) {
            this.showFormDlg = false;
            EventBus.$emit('error-message', `Σφάλμα κατά την αποθήκευση του εγγράφου`);
        },
        resetCurrent() {
            this.current = {
                id: null,
                srv_id: this.srv_id,
                aa: null,
                descr: null,
                notes: null,
                docfile: null,
                done: false,
                done_at: null
            }
        },
        viewDocfile(doc) {
            window.open(doc.docfile);
        },
        uploadDocfile(doc) {
            this.uploadDocfileUrl = `/docs/${doc.id}/docfile`;
            this.showUploadFileDlg = true;
        },
        onUploadDocfile(doc) {
            this.showUploadFileDlg = false;
            var idx = this.docs.findIndex(x => x.id == doc.id);
            this.docs[idx].docfile = doc.docfile;
        },
        deleteDocfile(doc) {
            // Confirm and delete
            this.$confirm(`Να διαγραφεί το αρχείο του εγγράφου?`, {title: "Διαγραφή αρχείου"})
            .then(res => {
                if (res) {
                    axios.delete(`/docs/${doc.id}/docfile`, {
                        headers: {Authorization: this.$store.getters.authHeader}
                    }).then((response) => {
                        var idx = this.docs.findIndex(x => x.id == doc.id);
                        this.docs[idx].docfile=null;
                        EventBus.$emit('success-message', `Επιτυχής διαγραφή του αρχείου`);
                    }).catch(error => {
                        EventBus.$emit('error-message', 'Σφάλμα κατά τη διαγραφή του αρχείου');
                    });
                }
            });
        },
        editNotes(doc) {
            this.editedNotes = doc.notes;
            this.showNotesDlg = true;
            this.notesUrl=`/docs/${doc.id}/notes`;
        },
        onSaveNotes(doc) {
            var idx = this.docs.findIndex(x => x.id == doc.id);
            this.docs[idx].notes=doc.notes;
            this.showNotesDlg = false;
        },
        onCancelNotes() {
            console.log("cancel notes dialog");
            this.showNotesDlg = false;
        }
       
    }

}
</script>

<style>

</style>
