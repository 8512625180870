<template>
    <div>
        <v-toolbar flat color="white" class=mb-1>
            <v-toolbar-title>Δημιουργία Νέας Επαφής</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>

        <client-form
            @created="onCreated"
            @error="onError"
            @cancel="onCancel">
        </client-form>

    </div>
</template>

<script>
import { EventBus } from '@/app.js'
import ClientForm from './Form'

export default {
    components: {
       'client-form': ClientForm,
    },
    methods: {
        onCreated(newClient) {
            this.$router.push({name: 'clientShow', params:{client_id: newClient.id}});
            EventBus.$emit('success-message', 'Η επαφή δημιουργήθηκε με επιτυχία')
        },
        onCancel() {
            this.$router.go(-1);
        },
        onError(error) {
            console.log(error);
        }
    }
}
</script>

<style>

</style>