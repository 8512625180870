<template>
    <div>
        <v-card>
            <v-card-title>
                <span class="headline">{{formTitle}}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-form ref="form" @keydown.native.enter.prevent="onSave">
                    <v-layout column>
                         <v-flex>
                             <v-menu
                                ref="applyMenu"
                                v-model="applyMenu"
                                :return-value.sync="localSrv.apply"
                                transition="scale-transition"
                                :close-on-content-click="false"
                                offset-y
                                min-width="290px"
                            >    
                                <template v-slot:activator="{on, attrs}">
                                    <v-text-field
                                        v-model="applyFormatted" 
                                        label="Καταχώρηση"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                         :rules="inputRules.apply"
                                    ></v-text-field>
                                </template>
                                <v-date-picker 
                                    v-model="localSrv.apply" 
                                    no-title 
                                    scrollable 
                                    locale="el"
                                    :first-day-of-week="1"
                                >
                                    <v-spacer></v-spacer>
                                    <v-btn text color="red darken-1" @click="applyMenu = false">ΑΚΥΡΩΣΗ</v-btn>
                                    <v-btn text color="green darken-1" @click="$refs.applyMenu.save(localSrv.apply)">ΕΠΙΛΟΓΗ</v-btn>
                                </v-date-picker>
                            </v-menu>
                         </v-flex>
                         <v-flex>
                            <v-select
                                label="Είδος Αίτησης (*)"
                                v-model="localSrv.csrv"
                                :items="csrvs"
                                item-text="descr"
                                item-value="id"
                                clearable
                                :return-object="true"
                                :rules="inputRules.csrv">
                            </v-select>
                        </v-flex>
                        <v-flex>
                            <v-textarea
                                label="Σημειώσεις"
                                v-model="localSrv.notes">
                            </v-textarea>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions>  
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text :loading="loading" @click="onSave">ΑΠΟΘΗΚΕΥΣΗ</v-btn>
                <v-btn color="red darken-1" text @click="onCancel">ΑΚΥΡΩΣΗ</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import axios from '@/plugins/axios'
import { EventBus } from '@/app.js'
import {formatDate} from '@/util'

export default {
    props: {
        srv: {
            required: true
        }
    },
    created() {
        this.fetchCsrvs();
    },
    mounted() {
        Object.assign(this.localSrv, this.srv);
        this.$refs.form.resetValidation();
    },
    watch: {
        srv(newVal, oldVal) {
            Object.assign(this.localSrv, this.srv);
            this.$refs.form.resetValidation();
        },
    },
    computed: {
        formTitle() {
            if (!this.localSrv.id) {
                return "Καταχώρηση νέας αίτησης";
            }
            else {
                return "Επεξεργασία αίτησης";
            }
        },
        applyFormatted() {
            return formatDate(this.localSrv.apply);
        }
    },
    data() {
        return {
            csrvs: [],
            localSrv: {
                id: null,
                client_id: null,
                csrv: null,
                apply: null,
                notes: null,
            },
            inputRules: {
                csrv: [
                    v => !!v || "Επιλέξτε το είδος της αίτησης",
                ],
                apply: [
                    v => !!v || "Επιλέξτε την ημ/νία υποβολής της αίτησης",
                ],
            },
            loading: false,
            applyMenu: false,
        }
    },
    methods: {
        fetchCsrvs() {
            axios.get('/csrvs', {
                headers: {Authorization: this.$store.getters.authHeader}
            }).then(response => {
                this.csrvs = response.data;
            }).catch(error => {
                EventBus.$emit('error-message', 'Σφάλμα κατά την ανάκτηση των διαθέσιμων υπηρεσιών')
            });
        },
        onSave() {
            if (!this.$refs.form.validate()) return;
            this.$refs.form.resetValidation();
            var newSrv = {
                id: this.localSrv.id,
                client_id: this.localSrv.client_id,
                csrv_id: this.localSrv.csrv.id,
                apply: this.localSrv.apply,
                notes: this.localSrv.notes
            }
            if (!newSrv.id) this.create(newSrv);
            else this.update(newSrv);
        },
        onCancel() {
            this.$refs.form.resetValidation();
            this.$emit('cancel');
        },
        create(newSrv) {
            this.loading = true;
            axios.post('/srvs', newSrv, {headers: {Authorization: this.$store.getters.authHeader}})
            .then(response => {
                this.loading = false;
                this.$emit('created', response.data);
            }).catch(error => {
                this.loading = false;
                this.$emit('error', error);
            });
        },
        update(newSrv) {
            this.loading = true;
            axios.put(`/srvs/${newSrv.id}`, newSrv, {headers: {Authorization: this.$store.getters.authHeader}})
            .then(response => {
                this.loading = false;
                this.$emit('updated', response.data);
            }).catch(error => {
                this.loading = false;
                this.$emit('error', error);
            });
        }

    },

}
</script>

<style>

</style>