<template>
    <div>
        <v-card>
            <v-card-title>
                <span class="headline">{{formTitle}}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-form ref="form" @keydown.native.enter.prevent="onSave">
                    <v-layout column>
                         <v-flex>
                            <v-select
                                label="Κατηγορία Τίτλου Σπουδών (*)"
                                v-model="localEdu.cedu"
                                :items="cedus"
                                item-text="descr"
                                item-value="id"
                                :return-object="true"
                                :rules="inputRules.cedu">
                            </v-select>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field
                                label="Ιδρυμα"
                                v-model="localEdu.idrima"
                                counter="255">
                            </v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field
                                label="Σχολή"
                                v-model="localEdu.sxoli"
                                counter="255">
                            </v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field
                                label="Τίτλος"
                                v-model="localEdu.titlos"
                                counter="255">
                            </v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field
                                label="Έτος αποφοίτησης"
                                v-model="localEdu.grad_year"
                                type="number">
                            </v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field
                                label="Βαθμός"
                                v-model="localEdu.grade"
                                type="number">
                            </v-text-field>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions>  
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text :loading="loading" @click="onSave">ΑΠΟΘΗΚΕΥΣΗ</v-btn>
                <v-btn color="red darken-1" text @click="onCancel">ΑΚΥΡΩΣΗ</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import axios from '@/plugins/axios'
import { EventBus } from '@/app.js'

export default {
    props: {
        edu: {
            required: true
        }
    },
    created() {
        this.fetchCedus();
    },
    mounted() {
        Object.assign(this.localEdu, this.edu);
        //this.localEdu = this.edu;
        this.$refs.form.resetValidation();
    },
    watch: {
        edu(newVal, oldVal) {
            Object.assign(this.localEdu, this.edu);
            //this.localEdu = this.edu;
            this.$refs.form.resetValidation();
        },
    },
    computed: {
        formTitle() {
            if (!this.localEdu.id) {
                return "Καταχώρηση νέου τίτλου σπουδών";
            }
            else {
                return "Επεξεργασία τίτλου σπουδών";
            }
        }
    },
    data() {
        return {
            cedus: [],
            localEdu: {
                id: null,
                client_id: null,
                cedu: null,
                idrima: null,
                sxoli: null,
                titlos: null,
                grad_year: null,
                grade: null,
                certification: null,
            },
            inputRules: {
                cedu: [
                    v => !!v || "Επιλέξτε την κατηγορία του τίτλου σπουδών",
                ]
            },
            loading: false,
        }
    },
    methods: {
        fetchCedus() {
            axios.get('/cedus', {
                headers: {Authorization: this.$store.getters.authHeader}
            }).then(response => {
                this.cedus = response.data;
            }).catch(error => {
                EventBus.$emit('error-message', 'Σφάλμα κατά την ανάκτηση των κατηγοριών εκπαίδευσης')
            });
        },
        onSave() {
            if (!this.$refs.form.validate()) return;
            this.$refs.form.resetValidation();
            var newEdu = {
                id: this.localEdu.id,
                client_id: this.localEdu.client_id,
                cedu_id: this.localEdu.cedu.id,
                idrima: this.localEdu.idrima,
                sxoli: this.localEdu.sxoli,
                titlos: this.localEdu.titlos,
                grad_year: this.localEdu.grad_year,
                grade: this.localEdu.grade
            }
            if (!newEdu.id) this.create(newEdu);
            else this.update(newEdu);
        },
        onCancel() {
            this.$refs.form.resetValidation();
            this.$emit('cancel');
        },
        create(newEdu) {
            this.loading = true;
            axios.post('/edus', newEdu, {headers: {Authorization: this.$store.getters.authHeader}})
            .then(response => {
                this.loading = false;
                this.$emit('created', response.data);
            }).catch(error => {
                this.loading = false;
                this.$emit('error', error);
            });
        },
        update(newEdu) {
            this.loading = true;
            axios.put(`/edus/${newEdu.id}`, newEdu, {headers: {Authorization: this.$store.getters.authHeader}})
            .then(response => {
                this.loading = false;
                this.$emit('updated', response.data);
            }).catch(error => {
                this.loading = false;
                this.$emit('error', error);
            });
        }

    },

}
</script>

<style>

</style>