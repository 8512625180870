export default {

    // Get App Title
    appTitle(state) {
        return state.appTitle;
    },

    // Get connected user
    user(state) {
        return state.user;
    },

    // Check if user is authenticated
    isAuth(state) {
        return state.user.token !== null;
    },

    // Check if connected user is administrator
    isAdmin(state, getters) {
        if (!getters.isAuth) return false;
        if (state.user.admin == 1)
            return true;
        return false;
    },

    // Get Api auth header
    authHeader(state) {
        return "Bearer " + state.user.token;
    },


}