<template>
    <div>
        <v-dialog max-width="500px" persistent v-model="show">
            <v-card>
                <v-card-title>
                    <span class="headline">Επαναφορά κωδικού πρόσβασης</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <div class="subheading my-5">
                        <span class="font-weight-bold">Όνομα χρήστη: </span>{{user.name}}
                    </div>
                    <div class="subheading  my-5">
                        <span class="font-weight-bold">Email: </span>{{user.email}}
                    </div>
                    <v-form ref="form" @keydown.native.enter.prevent="save">
                        <v-text-field
                            id="password"
                            label="Νέος κωδικός (*)"
                            v-model="password"
                            append-icon="mdi-cached"
                            @click:append="generatePassword"
                            :rules="passwordRules">
                        </v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="save" :loading="loading">ΚΑΤΑΧΩΡΗΣΗ</v-btn>
                    <v-btn color="red darken-1" text @click="cancel">ΑΚΥΡΩΣΗ</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from '@/plugins/axios'

export default {
    props: ['show', 'user'],
    data() {
        return {
            password: '',
            passwordRules: [
                 v => !!v || "Αυτό το πεδίο είναι υποχρεωτικό",
            ],
            loading: false,
        }
    },
    methods: {
        save() {
            if (!this.$refs.form.validate()) return;
            this.loading=true;
            const options = {
                headers: {Authorization: this.$store.getters.authHeader}
            };
            const data = {
                    email: this.user.email,
                    password: this.password
                }
            axios.post('/users/reset-password', data, options)
            .then(response => {
                this.loading = false;
                this.$refs.form.resetValidation();
                this.password = '';
                this.$emit('success', response.data);
            }).catch(error => {
                this.loading = false;
                this.$refs.form.resetValidation();
                this.password = '';
                this.$emit('error', error);
            });
        },
        cancel() {
            this.password = '';
            this.$refs.form.resetValidation();
            this.$emit('cancel');
        },
        generatePassword() {
            var generator = require('password-generator');
            this.password = generator(8, false);
        },
    }
}
</script>

<style>

</style>
