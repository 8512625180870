<template>
    <div>

        <!-- Csrv form dialog -->
        <template>
            <v-dialog max-width="600px" persistent v-model="showFormDlg">
                <csrv-form 
                    :csrv="current" 
                    @created="onCreateCsrv"
                    @updated="onUpdateCsrv" 
                    @error="onError"
                    @cancel="showFormDlg=false">
                </csrv-form>
            </v-dialog>
        </template>

        <!-- Print csrv to pdf -->
        <html-to-pdf
            :show-layout="false"
            :enable-download="false"
            :preview-modal="true"
            :paginate-elements-by-height="1400"
            filename="hee hee"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            ref="print2pdf">
            <template slot="pdf-content">
                <pdf-content :csrv="csrv4print"></pdf-content>
            </template>
        </html-to-pdf>

        <v-toolbar flat color="white" class=mb-1>
            <v-toolbar-title>Υπηρεσίες</v-toolbar-title>
            <v-spacer></v-spacer>
            
            <!-- New csrv button -->
            <v-btn color="primary" text dark class="mb-2" @click="createCsrv">
                <v-icon left>mdi-plus-circle-outline</v-icon>
                ΝΕΑ ΥΠΗΡΕΣΙΑ
            </v-btn>

            <!-- Refresh button -->
            <v-btn color="warning" text dark class="mb-2" @click="fetch">
                <v-icon left>mdi-replay</v-icon>
                ΑΝΑΝΕΩΣΗ
            </v-btn>
        </v-toolbar>


        <!-- Datatable -->
        <v-data-table
            :headers="headers"
            :items = "csrvs"
            must-sort
            :loading="listLoading"
            loadingText="Ανάκτηση εγγραφών"
            :sort-by.sync="options.sortBy"
            :sort-desc.sync="options.sortDesc"
            :items-per-page.sync="options.itemsPerPage"
            no-data-text="Δεν υπάρχουν καταχωρημένες υπηρεσίες"
            class="elevation-1"
            :footer-props="{
                'items-per-page-text': 'Εγγραφές ανά σελίδα'
            }"
            :expanded.sync="expanded"
            show-expand
        >
            <template slot="footer.page-text" slot-scope="item">
                Εγγραφές {{item.pageStart}} έως {{item.pageStop}} από {{item.itemsLength}}
            </template>
            <template v-slot:item.updated_at="{item}">
                <div>{{ item.updated_at | moment("DD/MM/YYYY") }}, {{ item.updated_at | moment("HH:mm") }}</div>
            </template>
            <template v-slot:expanded-item="{headers, item}">
                <td :colspan="headers.length">
                    <cdocs-list 
                        :csrv_id="item.id" 
                        @count="onCdocsCount($event, item)"
                        class="my-4">
                    </cdocs-list>
                </td>
            </template>
            <template v-slot:item.actions="{item}">
                <div class="layout">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                class="mr-2"
                                @click="editCsrv(item)"
                                color="blue"
                                v-on="on">
                                mdi-lead-pencil
                            </v-icon>
                        </template>
                        <span>Επεξεργασία υπηρεσίας</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                class="mr-2"
                                @click="deleteCsrv(item)"
                                color="red"
                                v-on="on">
                                mdi-delete
                            </v-icon>
                        </template>
                        <span>Διαγραφή υπηρεσίας</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                class="mr-2"
                                @click="printCsrv(item)"
                                color="green"
                                v-on="on">
                                mdi-printer
                            </v-icon>
                        </template>
                        <span>Εκτύπωση δικαιολογητικών</span>
                    </v-tooltip>
                </div>
            </template>

        </v-data-table>
    </div>
</template>

<script>
import axios from '@/plugins/axios'
import { EventBus } from '@/app.js'
import CdocsList from '@/components/Cdocs/List'
import VueHtml2pdf from 'vue-html2pdf'
import PrintCsrv from './Print'
import CsrvForm from './Form'

export default {
    components: {
        'csrv-form': CsrvForm,
        'cdocs-list': CdocsList,
        'html-to-pdf': VueHtml2pdf,
        'pdf-content': PrintCsrv,
    },
    created() {
        this.fetch();
    },
    computed: {
        headers() {
            return [
                {text: "Ενημέρωση", value: "updated_at"},
                {text: "Περιγραφή", value: "descr"},
                {text: "Εγγραφα", value: "cdocs_n"},
                {text: "Αιτήσεις", value: "srvs_n"},
                {text: "Ενέργειες", value: "actions", sortable: false},
                {text: "", value: "data-table-expand"}
            ]
        }
    },
    data() {
        return {
            csrvs: [],
            expanded: [],
            options: {
                sortBy: 'updated_at',
                sortDesc: true,
                itemsPerPage: 10,
            },
            loading: false,
            showFormDlg: false,
            current: {},
            csrv4print: {},
        }
    },
    
    methods: {
        fetch() {
            this.listLoading = true;
            axios.get('/csrvs', {
                headers: {Authorization: this.$store.getters.authHeader}
            }).then(response => {
                this.csrvs = response.data;
                this.listLoading = false;
            }).catch(error => {
                this.listLoading = false;
                EventBus.$emit('error-message', 'Σφάλμα κατά την ανάκτηση των υπηρεσιών')
            });
        },
        createCsrv() { 
            this.resetCurrent();
            this.showFormDlg = true;
        },
        editCsrv(csrv) {
            this.current = csrv;
            this.showFormDlg = true;
        },
        deleteCsrv(csrv) {
            // Confirm and delete
            this.$confirm(`Να διαγραφεί η υπηρεσία "${csrv.descr}"`, {title: "Διαγραφή υπηρεσίας"})
            .then(res => {
                if (res) {
                    axios.delete(`/csrvs/${csrv.id}`, {
                        headers: {Authorization: this.$store.getters.authHeader}
                    }).then((response) => {
                        var index = this.csrvs.findIndex(c => c.id == csrv.id);
                        this.csrvs.splice(index, 1);
                        EventBus.$emit('success-message', `Επιτυχής διαγραφή της υπηρεσίας "${csrv.descr}"`);
                    }).catch(error => {
                        EventBus.$emit('error-message', error.response.data.message);
                    });
                }
            });
        },
        onCreateCsrv(csrv) {
            this.showFormDlg=false;
            this.csrvs.push(csrv);
            EventBus.$emit('success-message', `Η υπηρεσία καταχωρήθηκε με επιτυχία`);
        },
        onUpdateCsrv(csrv) {
            this.showFormDlg = false;
            Object.assign(this.current, csrv);
            EventBus.$emit('success-message', `Τα στοιχεία της υπηρεσίας ενημερώθηκαν με επιτυχία`);
        },
        onError(error) {
            this.showFormDlg = false;
            EventBus.$emit('error-message', `Σφάλμα κατά την αποθήκευση της υπηρεσίας`);
        },
        resetCurrent() {
            this.current = {
                id: null,
                descr: null,
                cdocs_n: null,
                srvs_n: null,
            }
        },
        onCdocsCount(cdocs_n, csrv) {
            csrv.cdocs_n = cdocs_n
        },
        printCsrv(csrv) {
            axios.get(`/csrvs/${csrv.id}`, {
                headers: {Authorization: this.$store.getters.authHeader}
            }).then(response => {
                this.csrv4print = response.data;
                this.$refs.print2pdf.generatePdf();
            }).catch(error => {
               console.log(error);
            });
        },
    }
}
</script>

<style>

</style>