<template>
    <div>
        <v-toolbar flat color="white" class=mb-1>
            <v-toolbar-title>Επαφές</v-toolbar-title>
            <v-spacer></v-spacer>
            
            <!-- New client button -->
            <v-btn color="primary" text dark class="mb-2" @click="createClient">
                <v-icon left>mdi-plus-circle-outline</v-icon>
                ΝΕΑ ΕΠΑΦΗ
            </v-btn>

            <!-- Refresh button -->
            <v-btn color="warning" text dark class="mb-2" @click="fetch">
                <v-icon left>mdi-replay</v-icon>
                ΑΝΑΝΕΩΣΗ
            </v-btn>
        </v-toolbar>

        <v-card elevation="0">
            <v-card-title>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Αναζήτηση"
                    single-line
                    hide-details
                    clearable>
                </v-text-field>
            </v-card-title>
        </v-card>
        

        <!-- Datatable -->
        <v-data-table
            :headers="headers"
            :items = "clients"
            item-key="clients.id"
            must-sort
            :loading="listLoading"
            :search="search"
            loadingText="Ανάκτηση εγγραφών"
            :sort-by.sync="options.sortBy"
            :sort-desc.sync="options.sortDesc"
            :items-per-page.sync="options.itemsPerPage"
            no-data-text="Δεν υπάρχουν επαφές"
            class="elevation-1"
            :footer-props="{
                'items-per-page-text': 'Εγγραφές ανά σελίδα'
            }"
        >
            <template slot="footer.page-text" slot-scope="item">
                Εγγραφές {{item.pageStart}} έως {{item.pageStop}} από {{item.itemsLength}}
            </template>
            <template v-slot:item.active="{item}">
                <v-icon v-if="item.active==1" color="green">mdi-check</v-icon>
                <v-icon v-if="!item.active==1" color="red">mdi-block-helper</v-icon>
            </template>
            <template v-slot:item.updated_at="{item}">
                <div>{{ item.updated_at | moment("DD/MM/YYYY") }}, {{ item.updated_at | moment("HH:mm") }}</div>
            </template>
            <template v-slot:item.actions="{item}">
                <div class="layout">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                class="mr-2"
                                @click="showClient(item)"
                                color="green"
                                v-on="on">
                                mdi-account
                            </v-icon>
                        </template>
                        <span>Προβολή επαφής</span>
                    </v-tooltip>
                </div>
            </template>

        </v-data-table>
    </div>
</template>

<script>
import axios from '@/plugins/axios'
import { EventBus } from '@/app.js'

export default {
    components: {

    },
    computed: {
        
    },
    watch: {
       
    },
    data() {
        return {
            clients: [],
            headers: [
                {text: "Ενεργός", value: "active", filterable: false},
                {text: "Ενημέρωση", value: "updated_at", filterable: false},
                {text: "Επωνυμία", value: "lastname"},
                {text: "Ονομα", value: "firstname"},
                {text: "Πατρώνυμο", value: "fathername"},
                {text: "Ενέργειες", value: "actions", sortable: false, filterable: false}
            ],
            options: {
                sortBy: 'updated_at',
                sortDesc: true,
                itemsPerPage: 10,
            },
            listLoading: false,
            search: ''
        }
    },
    created() {
        this.fetch();
    },
    methods: {
        fetch() {
            this.listLoading = true;
            axios.get('/clients', {
                headers: {Authorization: this.$store.getters.authHeader}
            }).then(response => {
                this.clients = response.data;
                this.listLoading = false;
            }).catch(error => {
                this.listLoading = false;
                EventBus.$emit('error-message', 'Σφάλμα κατά την ανάκτηση των επαφών')
            });
        },
        showClient(client) {
            this.$router.push({name: 'clientShow', params: {client_id: client.id}})
        },
        deleteClient(client) {
            // Confirm and delete
            this.$confirm(`Να διαγραφεί η επιλεγμένη επαφή?`, {title: "Διαγραφή επαφής"})
            .then(res => {
                if (res) {
                    axios.delete(`/clients/${client.id}`, {
                        headers: {Authorization: this.$store.getters.authHeader}
                    }).then((response) => {
                        var index = this.clients.findIndex(c => c.id == client.id);
                        this.clients.splice(index, 1);
                        EventBus.$emit('success-message', `Επιτυχής διαγραφή της επιλεγμένης επαφής`);
                    }).catch(error => {
                        EventBus.$emit('error-message', error.response.data.message);
                    });
                }
            });
        },
        createClient() { 
            this.$router.push({name: 'clientCreate'})
        },
        
    }
}
</script>

<style>

</style>