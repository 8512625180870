<template>
    <div>
        <v-card>
            <v-divider></v-divider>
            <v-card-text>
                <v-form dense ref="clientForm" @keydown.native.enter.prevent="onSaveClient">
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-text-field 
                                label="Επωνυμία (*)"
                                v-model="localClient.lastname"
                                :rules="inputRules.lastname">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field 
                                label="Ονομα"
                                v-model="localClient.firstname">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-text-field 
                                label="Πατρώνυμο"
                                v-model="localClient.fathername">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                label="Μητρώνυμο"
                                v-model="localClient.mothername">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="4">
                            <v-text-field
                                label="Εθνικότητα"
                                v-model="localClient.nationality">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-menu
                                ref="birthdateMenu"
                                v-model="birthdateMenu"
                                :return-value.sync="localClient.birthdate"
                                transition="scale-transition"
                                :close-on-content-click="false"
                                offset-y
                                min-width="290px"
                            >    
                                <template v-slot:activator="{on, attrs}">
                                    <v-text-field
                                        v-model="birthdateFormatted"
                                        label="Ημ/νία γέννησης"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker 
                                    ref="birthdatePicker"
                                    v-model="localClient.birthdate" 
                                    :max="new Date().toISOString().substr(0,10)"
                                    min="1920-01-01"
                                    locale="el"
                                    :first-day-of-week="1"
                                    @change="saveBirthdate"
                                >
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-select
                                label="Φύλο"
                                v-model="localClient.sex"
                                :items="sexMap"
                                item-text="descr"
                                item-value="id"
                                clearable
                                :return-object="false">
                                <template v-slot:selection="{item}">
                                   <div v-if="item.id==0">Γυναίκα</div>
                                   <div v-if="item.id==1">Άντρας</div>
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="4">
                            <v-text-field
                                label="Α.Δ.Τ."
                                v-model="localClient.adt">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                label="Α.Φ.Μ."
                                v-model="localClient.afm">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                label="Α.Μ.Κ.Α."
                                v-model="localClient.amka">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="3">
                            <v-text-field
                                label="E-mail"
                                v-model="localClient.email">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field
                                label="Σταθερό τηλέφωνο"
                                v-model="localClient.phone">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field
                                label="Κινητό τηλέφωνο"
                                v-model="localClient.mobil">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field
                                label="Φαξ"
                                v-model="localClient.fax">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="3">
                            <v-text-field
                                label="Πόλη"
                                v-model="localClient.city">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field
                                label="Περιοχή"
                                v-model="localClient.area">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field
                                label="Διεύθυνση"
                                v-model="localClient.address">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field
                                label="Ταχ. κώδικας"
                                v-model="localClient.tk">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-flex xs12>
                        <v-switch
                            label="Ενεργός"
                            v-model="localClient.active">
                        </v-switch>
                    </v-flex>

                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn color="green darken-1" text @click="onSaveClient" :loading="loading">ΑΠΟΘΗΚΕΥΣΗ</v-btn>
                <v-btn color="red darken-1" text @click="onCancel">ΑΚΥΡΩΣΗ</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import axios from '@/plugins/axios'
import { EventBus } from '@/app.js'
import {nullClient, formatDate} from '@/util'

export default {
    props: {
        client: {
           type: Object,
           default() {
               var defaultClient = nullClient();
               defaultClient.active = true;
               return defaultClient;
           } 
        }
    },
    computed: {
        birthdateFormatted() {
            return formatDate(this.localClient.birthdate);
        },
        sexMap() {
            return [
                {
                    id: 0,
                    descr: "Γυναίκα"    
                },
                {
                    id: 1,
                    descr: "Άντρας"    
                }
            ]
        }
    },
    mounted() {
        this.localClient = this.client;
        this.$refs.clientForm.resetValidation();
    },
    watch: {
        client(val) {
            this.localClient = val;
        },
        birthdateMenu(val) {
            val && setTimeout(() => (this.$refs.birthdatePicker.activePicker = 'YEAR'))
        }
    },
    data() {
        return {
            localClient: nullClient(),
            inputRules: {
                lastname: [
                    v => !!v || "Πληκτρολογείστε την επωνυμία της επαφής",
                ],
            },
            loading: false,
            birthdateMenu: false,
        }
    },
    methods: {
        saveBirthdate(date) {
            this.$refs.birthdateMenu.save(date);
        },
        onSaveClient() {
            if (!this.$refs.clientForm.validate()) return;
            this.$refs.clientForm.resetValidation();
            this.loading=true;
            if (!this.localClient.id) this.createClient(this.localClient);
            else this.updateClient(this.localClient);
        },
        onCancel() {
            this.$emit('cancel');
        },
        createClient(newClient) {
            console.log(newClient);
            axios.post('/clients', newClient, {headers: {Authorization: this.$store.getters.authHeader}})
            .then(response => {
                this.loading = false;
                this.localClient = response.data;
                this.$emit('created', this.localClient);
            }).catch(error => {
                this.loading = false;
                this.$emit('error', error);
            });
        },
        updateClient(newClient) {
            axios.put(`/clients/${newClient.id}`, newClient, {headers: {Authorization: this.$store.getters.authHeader}})
            .then(response => {
                this.loading = false;
                this.localClient = response.data;
                this.$emit('updated', this.localClient);
            }).catch(error => {
                this.loading = false;
                this.$emit('error', error);
            });
        },
        
    }
}
</script>

<style>

</style>