import axios from '@/plugins/axios'

export default {
    
    // Login
    login(context, credentials) {

        return new Promise((resolve, reject) => {

            // ajax request with given credentials
            axios.post('/users/login', {
                email: credentials.email,
                password: credentials.password
            }).then (response => {
                var user = response.data;
                sessionStorage.setItem('user_id', user.id);
                sessionStorage.setItem('user_name', user.name);
                sessionStorage.setItem('user_email', user.email);
                sessionStorage.setItem('user_active', user.active);
                sessionStorage.setItem('user_admin', user.admin);
                sessionStorage.setItem('user_token', user.token);
                sessionStorage.setItem('user_token_id', user.token_id);
                sessionStorage.setItem('user_created_at', user.created_at);
                sessionStorage.setItem('user_updated_at', user.updated_at);
                context.commit("login", user);
                resolve(user);
            }).catch(error => {
                reject(error);
            });
        });
    },

    // Logout
    logout(context) {

        if (!context.getters.isAuth) return;

        return new Promise((resolve, reject) => {
            
            // Send ajax request to delete token on the server
            const data = {token_id: context.state.user.token_id};
            const options = {
                headers: {Authorization: context.getters.authHeader}
            };
            axios.post('/users/logout', data, options)
            .then(response => {

                // Delete from storage
                sessionStorage.removeItem('user_id');
                sessionStorage.removeItem('user_name');
                sessionStorage.removeItem('user_email');
                sessionStorage.removeItem('user_active');
                sessionStorage.removeItem('user_admin');
                sessionStorage.removeItem('user_token');
                sessionStorage.removeItem('user_token_id');
                sessionStorage.removeItem('user_created_at');
                sessionStorage.removeItem('user_updated_at');

                // Delete from state
                context.commit('logout');

                resolve(response);   

            }).catch(error => {
                reject(error);
            });

        });
    },

    // Set connected user
    setUser(context, user) {
        context.commit('login', user);
    },
    
}