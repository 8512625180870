<template>
    <div>
        
        <!-- Avatar -->
        <div>
            <v-avatar-uploader
                ref="avatarUploader"
                :url="avatar"
                :request="onUploadAvatar"
                :clickable="true"
                field="avatar">
            </v-avatar-uploader>

            <v-progress-circular
                class="avatar-progress"
                v-if="uploadingAvatar"
                :size="48"
                color="primary"
                indeterminate>
            </v-progress-circular>

            <!-- Reset avatar -->
            <div class="reset-avatar-btn">
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-icon
                            class="mr-2"
                            @click="resetAvatar"
                            color="error"
                            v-on="on">
                            mdi-delete
                        </v-icon>
                    </template>
                    <span>Διαγραφή φωτογραφίας</span>
                </v-tooltip>
            </div>
        </div>

        
        <!-- Κατάσταση -->
        <v-chip :color="statusColor" text-color="white" class="mt-4">
            {{client.active_descr}}
        </v-chip>
        <v-spacer></v-spacer>

        <!-- Δημιουργήθηκε -->
        <div class="mt-4 text-subtitle-2 text-bold">Δημιουργήθηκε</div>
        <div>{{client.created_at | moment("DD/MM/YYYY") }}, {{client.created_at | moment("HH:mm") }}</div>
        <div>{{client.created_by.name}}</div>
        
        <!-- Ενημερώθηκε -->
        <div class="mt-4 text-subtitle-2 text-bold">Τελευταία ενημέρωση</div>
        <div>{{client.updated_at | moment("DD/MM/YYYY") }}, {{client.updated_at | moment("HH:mm") }}</div>
        <div>{{client.updated_by.name}}</div>

        <!-- Κουμπιά Επεξεργασίας / διαγραφής -->
        <div>
            <v-btn color="primary" dark @click="onEditClient" class="btn-edit">
                    ΕΠΕΞΕΡΓΑΣΙΑ ΣΤΟΙΧΕΙΩΝ
            </v-btn>
        </div>
        <div>
            <v-btn color="error"  dark @click="onDeleteClient" class="btn-delete">
                ΔΙΑΓΡΑΦΗ ΕΠΑΦΗΣ
            </v-btn>
        </div>
    </div>
</template>

<script>
import axios from '@/plugins/axios'
import { EventBus } from '@/app.js'
import VuetifyAvatarUploader from 'vuetify-avatar-uploader'

export default {
    components: {
        'v-avatar-uploader': VuetifyAvatarUploader
    },
    props: {
        client: {
            required: true
        }
    },
    data() {
        return {
            avatar: '',
            uploadingAvatar: false
        }
    },
    mounted() {
        this.avatar = this.client.avatar;
    },
    computed: {
        statusColor() {
            if (this.client.active) return "success";
            else return "error";
        },
    },
    methods: {
        onUploadAvatar(form, config) {
            this.uploadingAvatar = true;
            axios.post(`/clients/${this.client.id}/avatar`, form, {
                headers: {Authorization: this.$store.getters.authHeader}
            }).then(response => {
                this.avatar = response.data.avatar;
                this.uploadingAvatar = false;
            }).catch(error => {
                this.uploadingAvatar = false;
                EventBus.$emit('error-message', 'Σφάλμα κατά την αποθήκευση της φωτογραφίας')
            });
           
        },
        resetAvatar() {
            this.$confirm('Να διαγραφεί η φωτογραφία της επαφής?', {title:'Διαγραφή φωτογραφίας'})
            .then(res => {
                if (res) {
                    this.uploadingAvatar = true;
                    axios.post(`/clients/${this.client.id}/avatar`, {reset: true}, {
                        headers: {Authorization: this.$store.getters.authHeader}
                    }).then(response => {
                        this.avatar = response.data.avatar;
                        this.uploadingAvatar = false;
                    }).catch(error => {
                        this.uploadingAvatar = false;
                        EventBus.$emit('error-message', 'Σφάλμα κατά την αποθήκευση της φωτογραφίας')
                    });
                }
            });
        },
        onEditClient() {
            this.$router.push({name: 'clientEdit', props: {client_id: this.client.id}})
        },
        onDeleteClient() {
            // Confirm and delete
            this.$confirm(`Να διαγραφεί η επιλεγμένη επαφή?`, {title: "Διαγραφή επαφής"})
            .then(res => {
                if (res) {
                    axios.delete(`/clients/${this.client.id}`, {
                        headers: {Authorization: this.$store.getters.authHeader}
                    }).then((response) => {
                        EventBus.$emit('success-message', `Επιτυχής διαγραφή της επαφής`);
                        this.$router.push({name: 'clientList'});
                    }).catch(error => {
                        EventBus.$emit('error-message', error.response.data);
                    });
                }
            });
        }

    }
}
</script>

<style>
    .v-avatar {
        height: 200px !important;
        width: 200px !important;
        border-radius: 0% !important;
    }
    .reset-avatar-btn {
        position: absolute;
        z-index: 100;
        padding: 0px;
        margin-top: -21px;
        margin-left: 200px;
    }
    .avatar-progress {
        position: absolute;
        z-index:200;
        padding: 0px;
        margin-top: -280px;
        margin-left: 78px;
    }
    .btn-edit {
        margin-top:20px;
        width: 210px;

    }
    .btn-delete {
        margin-top: 20px;
        width: 210px;
    }
</style>
   
