<template>
    <div class="main-div">
        <div class="logo" >
            <img src="/storage/logo2w.png" />
        </div>
        <h1 class="header">
            {{csrv.descr}}
        </h1>
        <ol>
            <li v-for="cdoc in cdocsSorted" :key="cdoc.id">{{cdoc.descr}}</li>
        </ol>
    </div>
  
</template>

<script>
import axios from '@/plugins/axios'
import VueHtml2pdf from 'vue-html2pdf'

export default {
    props: {
        csrv: {
            required: true
        },
    },
    computed: {
        cdocsSorted() {
            if (this.csrv.cdocs) {
                return this.csrv.cdocs.sort((a,b) => (a.aa > b.aa) ? 1 : ((b.aa > a.aa) ? -1 : 0));
            }
            else {
                return [];
            }
            
        }
    }
}
</script>

<style>
    .main-div {
        padding: 16px;
    }
    .logo {
        margin-top: 8px;
        text-align: center;
    }
    .header {
        margin-top: 25px;
        text-align: center;
    }
    ol {
        margin-left: 40px;
        margin-right: 40px;
        margin-top: 40px;
    }
    li {
        margin-bottom: 16px;
        text-align: justify;
    }
</style>