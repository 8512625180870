var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[[_c('v-dialog',{attrs:{"max-width":"600px","persistent":""},model:{value:(_vm.showFormDlg),callback:function ($$v) {_vm.showFormDlg=$$v},expression:"showFormDlg"}},[_c('csrv-form',{attrs:{"csrv":_vm.current},on:{"created":_vm.onCreateCsrv,"updated":_vm.onUpdateCsrv,"error":_vm.onError,"cancel":function($event){_vm.showFormDlg=false}}})],1)],_vm._v(" "),_c('html-to-pdf',{ref:"print2pdf",attrs:{"show-layout":false,"enable-download":false,"preview-modal":true,"paginate-elements-by-height":1400,"filename":"hee hee","pdf-quality":2,"manual-pagination":false,"pdf-format":"a4","pdf-orientation":"portrait","pdf-content-width":"800px"}},[_c('template',{slot:"pdf-content"},[_c('pdf-content',{attrs:{"csrv":_vm.csrv4print}})],1)],2),_vm._v(" "),_c('v-toolbar',{staticClass:"mb-1",attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Υπηρεσίες")]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","text":"","dark":""},on:{"click":_vm.createCsrv}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-circle-outline")]),_vm._v("\n            ΝΕΑ ΥΠΗΡΕΣΙΑ\n        ")],1),_vm._v(" "),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"warning","text":"","dark":""},on:{"click":_vm.fetch}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-replay")]),_vm._v("\n            ΑΝΑΝΕΩΣΗ\n        ")],1)],1),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.csrvs,"must-sort":"","loading":_vm.listLoading,"loadingText":"Ανάκτηση εγγραφών","sort-by":_vm.options.sortBy,"sort-desc":_vm.options.sortDesc,"items-per-page":_vm.options.itemsPerPage,"no-data-text":"Δεν υπάρχουν καταχωρημένες υπηρεσίες","footer-props":{
            'items-per-page-text': 'Εγγραφές ανά σελίδα'
        },"expanded":_vm.expanded,"show-expand":""},on:{"update:sortBy":function($event){return _vm.$set(_vm.options, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.options, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.options, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.options, "sortDesc", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.options, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.options, "itemsPerPage", $event)},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(item){return [_vm._v("\n            Εγγραφές "+_vm._s(item.pageStart)+" έως "+_vm._s(item.pageStop)+" από "+_vm._s(item.itemsLength)+"\n        ")]}},{key:"item.updated_at",fn:function(ref){
        var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("moment")(item.updated_at,"DD/MM/YYYY"))+", "+_vm._s(_vm._f("moment")(item.updated_at,"HH:mm")))])]}},{key:"expanded-item",fn:function(ref){
        var headers = ref.headers;
        var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('cdocs-list',{staticClass:"my-4",attrs:{"csrv_id":item.id},on:{"count":function($event){return _vm.onCdocsCount($event, item)}}})],1)]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"layout"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"blue"},on:{"click":function($event){return _vm.editCsrv(item)}}},on),[_vm._v("\n                            mdi-lead-pencil\n                        ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Επεξεργασία υπηρεσίας")])]),_vm._v(" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteCsrv(item)}}},on),[_vm._v("\n                            mdi-delete\n                        ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Διαγραφή υπηρεσίας")])]),_vm._v(" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"green"},on:{"click":function($event){return _vm.printCsrv(item)}}},on),[_vm._v("\n                            mdi-printer\n                        ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Εκτύπωση δικαιολογητικών")])])],1)]}}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }